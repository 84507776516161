@use '@angular/material' as mat;

@use './dark-mode.scss';
@use './spacing.scss';
@use './ce-layout.scss';
@use './search.scss';
@use './forms.scss';

@include mat.elevation-classes();
@include mat.app-background();

/*--bootstrap changes--*/
.form-check-input {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-print-color-adjust: exact;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check-input:checked[type='radio']:before {
  background: #fff;
  position: absolute;
  width: 11px;
  height: 11px;
  content: '';
  border-radius: 50%;
  vertical-align: middle;
  top: 4px;
  right: 0px;
  left: 5px;
}
.form-check-input[type='radio'] {
  border-radius: 50%;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.flex-column {
  flex-direction: column !important;
}
.d-flex {
  display: flex !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-row {
  flex-direction: row !important;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
@media (min-width: 768px) {
  .d-md-flex {
    display: flex !important;
  }
}
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border-radius: var(--bs-border-radius);
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(
    .invalid-tooltip
  ):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
  width: 100%;
  vertical-align: top;
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  unicode-bidi: isolate;
  border-spacing: 2px;
  border-color: gray;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-color: inherit;
}
.table > thead {
  vertical-align: bottom;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
th {
  text-align: -webkit-match-parent;
}

/*--table hightlight-row--*/
.hightlist-row1 {
  .product {
    color: var(--text-link) !important;
  }
  .customer-name {
    color: var(--text-link) !important;
  }
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-center {
  align-items: center !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: end !important;
}

.form-control {
  width: 100%;
  appearance: none;
  background-clip: padding-box;
}
input:not([type='image' i], [type='range' i], [type='checkbox' i], [type='radio' i]) {
  overflow-clip-margin: 0px !important;
  overflow: clip !important;
}
input[type='text' i] {
  padding-block: 1px;
  padding-inline: 2px;
}
.w-100 {
  width: 100% !important;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

.position-relative {
  position: relative !important;
}
.position-fixed {
  position: fixed !important;
}
.position-absolute {
  position: absolute !important;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
* {
  vertical-align: baseline;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.rounded-circle {
  border-radius: 50% !important;
}
img,
svg {
  vertical-align: middle;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  min-height: 100%;
  font-family: Satoshi;
  font-size: 16px;
  margin: 0 !important;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
}
::-moz-selection {
  /* Code for Firefox */
  color: var(--primary-0);
  background: var(--primary-100);
}

::selection {
  color: var(--primary-0);
  background: var(--primary-100);
}
.widget-overlay {
  .leftSidebar-widget {
    &:hover {
      .toggle-leftside {
        display: none !important;
      }
    }
  }
}
app-login {
  width: 100% !important;
}
/*---for strybook docs--*/

/*---font-awersome--*/
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/*----*/
.full-width {
  .skeleton-loader {
    margin-bottom: 0;
    border-radius: 0 !important;
    display: flex !important;
  }
}
:root {
  --primary-100: #141415;
  --primary-80: #262626;
  --primary-70: #323232;
  --primary-60: #626262;
  --primary-50: #bfbfbf;
  --primary-40: #dfdfdf;
  --primary-30: #ececec;
  --primary-20: #f6f6f6;
  --primary-0: #fff;
  .dark-mode {
    --primary-100: #fff;
    --primary-80: #f6f6f6;
    --primary-70: #ececec;
    --primary-60: #dfdfdf;
    --primary-50: #626262;
    --primary-40: #626262;
    --primary-30: #323232;
    --primary-20: #262626;
    --primary-0: #141415;
    --warning-80: var(--warning-180);
    // --warning-100: var(--warning-180);
    --warning-140: var(--warning-180);
    --success-80: var(--success-140);
    --success-60: var(--success-140);
    --text-link: var(--info-100);
    --text-white: var(--primary-100);
    --text-black: var(--primary-0);
    --text-mute: var(--primary-40);
    --light-120: var(--warning-180);
    /*--end dark-theme--*/
  }

  --light-120: #ebe1ad;
  --light-80: #fbf8ef;
  --light-100: #fff7cf;
  --success-140: #3b3e39;
  --success-120: #71966d;
  --success-100: #c7f4c2;
  --success-80: #edffeb;
  --success-60: #f8fff8;
  --info-120: #679298;
  --info-80: #eafcff;
  --warning-180: #3e3c39;
  --warning-160: #f88838;
  --warning-140: #996c49;
  --warning-120: #d1986b;
  --warning-100: #ffe4cf;
  --warning-80: #fff3ea;
  --alert-80: #ffeced;
  --alert-100: #f5c2c5;
  --alert-120: #c28e91;
  --alert-140: #be555b;
  --alert-160: #8b2228;
  --dark-4: rgba(36, 22, 35, 0.04);
  --dark-80: #f6f4f3;
  --dark-100: #dcd5d0;
  --dark-120: #a8968a;
  --info-100: #c3eef4;
  --info-140: #456569;
  --primary-brand-100: #002e27;
  --primary-brand-80: #08342d;
  --active-default: #0f69fa;
  --active-hover: rgba(112, 140, 241, 0.8);
  --text-link: var(--info-120);
  --text-white: var(--primary-0);
  --text-black: var(--primary-100);
  --text-mute: var(--primary-60);
}

/*--text-vaiables*/
$text-white: var(--text-white);
$text-link: var(--text-link);
$text-black: var(--text-black);
/*--text-vaiables*/

$primary-100: var(--primary-100);
$primary-80: var(--primary-80);
$primary-70: var(--primary-70);
$primary-60: var(--primary-60);
$primary-50: var(--primary-50);
$primary-40: var(--primary-40);
$primary-30: var(--primary-30);
$primary-20: var(--primary-20);
$primary-0: var(--primary-0);

@font-face {
  font-family: Satoshi;
  src:
    local(Satoshi-Regular),
    url(assets/fonts/Satoshi-Regular.woff2) format('woff2'),
    url(assets/fonts/Satoshi-Regular.woff) format('woff'),
    url(assets/fonts/Satoshi-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Satoshi;
  src:
    local(Satoshi-Medium),
    url(assets/fonts/Satoshi-Medium.woff2) format('woff2'),
    url(assets/fonts/Satoshi-Medium.woff) format('woff'),
    url(/assets/fonts/Satoshi-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Satoshi;
  src:
    local(Satoshi-Bold),
    url(/assets/fonts/Satoshi-Bold.woff2) format('woff2'),
    url(/assets/fonts/Satoshi-Bold.woff) format('woff'),
    url(/assets/fonts/Satoshi-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.h1-headers {
  font-family: Satoshi;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.h2-subheaders {
  font-family: Satoshi;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.h3-bold {
  font-family: Satoshi;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.h3-reg {
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.h4-case {
  font-family: Satoshi;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.h4-bold {
  font-family: Satoshi;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.h4-reg {
  font-family: Satoshi;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.h5-bold {
  font-family: Satoshi;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.h5-reg {
  font-family: Satoshi;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 1600px) {
  .h1-headers {
    font-size: 30px !important;
  }

  .h2-subheaders {
    font-size: 22px !important;
  }

  .h3-bold {
    font-size: 14px !important;
  }

  .h3-reg {
    font-size: 14px !important;
  }

  .h4-case {
    font-size: 12px !important;
  }

  .h4-bold {
    font-size: 12px !important;
  }

  .h4-reg {
    font-size: 12px !important;
  }
  .h5-bold {
    font-size: 10px !important;
  }
  .h5-reg {
    font-size: 10px !important;
  }
}

.primary-50 {
  color: var(--primary-50);
}
.primary-60 {
  color: var(--primary-60);
}
.primary-100 {
  color: var(--primary-100) !important;
}
.primary-0 {
  color: var(--primary-0) !important;
}
.primary-70 {
  color: var(--primary-70);
}
.bg-primary-20 {
  background-color: var(--primary-20) !important;
}
.primary-80 {
  color: var(--primary-80);
}
.success-120 {
  color: var(--success-120);
}
.success-100 {
  color: var(--success-100);
}
.warning-120 {
  color: var(--warning-120);
}
.alert-140 {
  color: var(--alert-140) !important;
}
.alert-160 {
  color: var(--alert-160);
}
.info-140 {
  color: var(--info-140);
}
.info-120 {
  color: var(--info-120);
}
.black {
  color: #141415;
}
.dark-green {
  color: var(--primary-brand-100);
}
.light-green {
  background-color: var(--primary-brand-80);
}
.strike {
  text-decoration: line-through;
}

.black-bg {
  .btn-primary {
    border: solid var(--border-width-1) var(--primary-0) !important;
    background-color: var(--primary-0) !important;
    color: var(--primary-100) !important;

    &.focus {
      border: solid var(--border-width-1) var(--info-120) !important;
      background-color: var(--primary-0) !important;
      color: var(--primary-100) !important;
    }
    &:hover {
      border: solid var(--border-width-1) var(--primary-40) !important;
      background-color: var(--primary-20) !important;
      color: var(--primary-100) !important;
    }
    &:active {
      border: solid var(--border-width-1) var(--info-120) !important;
      background-color: var(--primary-0) !important;
      color: var(--primary-0) !important;
    }
  }
  .btn-secondary {
    border: solid var(--border-width-1) var(--primary-60) !important;
    background-color: var(--primary-100) !important;
    color: var(--primary-0) !important;
    .loader-spin {
      width: 14px;
      // height: 14px;
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear;
    }
    .btn-icon {
      display: flex;
    }
    &:hover {
      border: solid var(--border-width-1) var(--primary-70) !important;
      background-color: var(--primary-70) !important;
      color: var(--primary-0) !important;
    }
    &:focus {
      border: solid var(--border-width-1) var(--info-120) !important;
      background-color: var(--primary-100) !important;
    }
    &:active {
      border: solid var(--border-width-1) var(--info-120) !important;
      background-color: var(--primary-100) !important;
      color: var(--primary-0) !important;
    }
  }
}
.activity-section {
  app-activity {
    z-index: 999;
    position: relative;
    &:first-child::before {
      top: 15px;
    }
    &:last-child::before {
      bottom: 0;
      top: 0;
    }
    &::before {
      position: absolute;
      content: '';
      width: 1px;
      height: -webkit-fill-available;
      background-color: var(--primary-50);
      left: 45px;
      right: auto;
      z-index: -1;
      top: 0;
    }
  }
}

.answer-selected {
  background-color: var(--success-60);
  border: solid var(--border-width-1) var(--success-120);
}
/*--widget-section--*/
.edit-mode {
  gap: var(--spacing-24) !important;
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: var(--radius-4);
  z-index: 999999999999999 !important;
  position: absolute;
  cursor: grab !important;
  width: 100%;
  .grid {
    border-color: var(--info-120);
    box-shadow: 4px 4px 4px 0 var(--dark-4);
  }
  .widget-placeholder {
    border-color: var(--info-120);
    box-shadow: 4px 4px 4px 0 var(--dark-4);
    .drag-drop-icon {
      display: block !important;
      cursor: grab;
      position: absolute;
      top: 28px;
      left: 5px;
    }
  }
}
.drag-drop-icon {
  color: var(--primary-50);
  svg {
    height: 16px;
  }
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}
.example-list {
  &.cdk-drop-list-dragging {
    min-width: 306px;
    overflow: hidden;
    .example-box:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
  .cdk-drag-placeholder {
    min-width: 306px;
  }
}
.widget-overlay {
  overflow: hidden;
  &:after {
    content: '';
    position: fixed;
    height: 100vh;
    width: 100%;
    background: var(--primary-0);
    opacity: 0.4;
    transition: opacity 0.25s ease-in-out;
    will-change: transform;
    z-index: 99999;
    top: 0;
  }
  .detail-layout {
    .leftSidebar-widget {
      z-index: 999999999 !important;
    }
  }
}
.widget-actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-16);
  padding: var(--spacing-16) 0;
  justify-content: center;
  text-align: center;
}
.widget-dropzone {
  border: var(--border-width-1) dashed var(--success-120);
  border-radius: var(--radius-4);
  padding: var(--spacing-24);
  color: var(--success-120);
  background-color: var(--success-60);
  text-align: center;
}
.edit-widgets {
  .remove-icon {
    position: absolute;
    cursor: pointer;
    top: -10px;
    right: 1px;
    height: 24px;
    width: 24px;
    background-color: var(--primary-100);
    color: var(--primary-0);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-4);
    transform: translate(0);
    transition: transform 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    z-index: 99999999;
    display: none;
  }
  &:hover {
    .remove-icon {
      display: flex;
    }
  }
  cursor: grab;
  &:after {
    content: '';
    position: absolute;
    border: solid var(--border-width-1) var(--primary-30);
    background-color: var(--primary-0);
    z-index: 9999;
    height: 100%;
    width: 100%;
    top: 0;
    border-radius: var(--radius-4);
    opacity: 0;
    left: 0;
  }
  &.detail-widget {
    border: none;
    background: var(--primary-20);
  }
  .drag-drop-icon {
    display: block !important;
    cursor: pointer;
  }
}
.widgets {
  position: relative;
  .drag-drop-icon {
    display: none;
    position: absolute;
    top: 28px;
    left: 5px;
  }
}

.widget-placeholder {
  border: var(--border-width-1) solid var(--primary-30);
  border-radius: var(--radius-4);
  padding: var(--spacing-24);
  height: 100%;
  min-width: 306px;
  background-color: var(--primary-0);
  height: auto;
  max-width: 342px;
  position: relative;
  cursor: grab;
  &:hover {
    border-color: var(--info-140);
    .drag-drop-icon {
      display: block !important;
      cursor: grab;
      position: absolute;
      top: 28px;
      left: 5px;
    }
    .tooltip-hover {
      display: block !important;
    }
  }

  &.alreadyuse-widgets {
    background-color: var(--primary-20);
    pointer-events: none !important;
    img {
      filter: grayscale(100%);
    }
  }
}

/*--widget-section--*/
.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}
.activities-widget {
  &:hover {
    .scrollbar-track {
      opacity: 0.4 !important;
    }
  }
}
.pull-right {
  float: right;
}
.white-space {
  white-space: nowrap;
}

.tooltip {
  opacity: 1 !important;
  padding: 0;
  z-index: 9999999 !important;
  &.show {
    opacity: inherit;
  }
  .tooltip-arrow {
    display: none;
  }
}
.tooltip-inner {
  max-width: initial;
  padding: inherit;
  color: inherit;
  text-align: inherit;
  background-color: inherit;
  border-radius: inherit;
}

.hidden {
  overflow: hidden;
}
.w-fit {
  width: fit-content;
}
.svg-60 {
  svg {
    path {
      fill: var(--primary-60);
    }
  }
}
.svg-100 {
  svg {
    path {
      fill: var(--primary-100);
    }
  }
}
.dot-divder {
  width: 4px;
  height: 4px;
  background-color: var(--primary-50);
  border-radius: var(--radius-100);
}
.vertical-line {
  width: 1px;
  background-color: var(--primary-40);
  height: 22px;
}

#ce-wrapper {
  padding-left: var(--spacing-64);
  padding-top: 59px; //change it in 59px when remove alert
}
.cursor-pointer {
  cursor: pointer;
}
[hidden] {
  display: none !important;
}
* {
  vertical-align: baseline;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.product-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 111px;
}
a {
  text-decoration: none;
}
.col-auto {
  flex: 0 0 auto;
  width: auto !important;
}
.cdk-menu-item:focus-visible {
  background-color: var(--primary-20);
  outline: none !important;
  box-shadow: none !important;
  border-radius: var(--radius-4);
}
.cdk-overlay-container {
  z-index: 20000000010 !important;
}

:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.store-icons {
  svg,
  app-ce-icon {
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: var(--radius-4);
    background-color: var(--primary-20);
  }
}
.sb-show-main.split {
  padding: 0px;
  .split-section {
    display: flex;
    height: 100vh;
    flex-direction: row;
    .split-item {
      padding: 1rem;
      flex: auto;
    }
  }
  .docs-story {
    .split-section {
      display: flex;
      flex-direction: row;
      height: auto;
      .split-item {
        padding: 1rem;
        flex: auto;
      }
    }
  }
}
.sb-show-main.divide {
  padding: 0px;
  .split-section {
    display: flex;
    height: 100vh;
    flex-direction: column;
    .split-item {
      padding: 1rem;
      flex: auto;
    }
  }
  .docs-story {
    .split-section {
      flex-direction: column;
      height: auto;
    }
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  opacity: unset !important;
}
.mat-mdc-form-field-infix {
  padding: 0 !important;
}
.mdc-evolution-chip-set__chips {
  margin-left: 0 !important;
  gap: var(--spacing-4) !important;
}
/*---mat progress bar--*/
.progress-data {
  .mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-height: 16px !important;
    border-radius: var(--radius-4);
    height: 16px !important;
  }
  .mdc-linear-progress {
    overflow: hidden !important;
  }
  .mdc-linear-progress__buffer {
    background-color: var(--primary-20) !important;
    height: 16px !important;
  }
  .mdc-linear-progress__bar-inner {
    border-top-width: 16px !important;
    border-radius: var(--radius-4);
  }
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  background-image: url(/assets/down-arrow.svg);
  width: 16px;
  height: 16px;
  border: none;
}
.page-heading-sec {
  height: 48px;
}

/*--overlay component--*/

app-modal {
  width: 100%;
}

.modal-backdrop {
  z-index: 20000000001 !important;
}

.modal-gallery-window {
  &.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, 0.32);
    opacity: 1 !important;
  }
}

.modal-gallery-dialog,
.cdk-overlay-pane.mat-mdc-dialog-panel.modal-gallery-dialog {
  // border: solid 1px var(--primary-30);
  height: 92vh !important;
  min-height: 92vh !important;
  max-height: 92vh !important;
  min-width: 95%;
  max-width: 100%;
  background: var(--primary-0) !important;
  border-radius: var(--radius-8) !important;
  // overflow-y: auto;

  &.modal-dialog {
    min-width: 95%;
    max-width: 95%;
  }

  .modal-content {
    padding: 0 !important;
    height: 100%;
    min-height: 100%;
    border: 0;
    //overflow-y: hidden;
  }
}

.modal-icon {
  width: 52px;
  height: 52px;
  background-color: var(--primary-20);
  border-radius: var(--radius-4);
  svg {
    path {
      fill: var(--primary-100);
    }
  }
}

.text-uppercase {
  text-transform: uppercase !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}

// .cdk-dialog-container,
// .mat-mdc-dialog-container {
//   min-width: 675px;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   padding: var(--spacing-32) var(--spacing-64);
//   border-radius: var(--radius-4);
//   background-color: var(--primary-0) !important;
//   box-shadow: 4px 4px 4px 0 var(--dark-4) !important;
//   gap: var(--spacing-32);
//   .modal-header {
//     border: none;
//     border-radius: 0;
//   }
//   .modal-footer {
//     border: none;
//     .modal-btns {
//       width: 47%;
//       &.danger {
//         button {
//           background-color: var(--alert-140) !important;
//           border-color: var(--alert-140) !important;
//         }
//       }
//     }
//   }
// }

.mat-mdc-dialog-container {
  min-width: auto !important;
}

.modal {
  z-index: 20000000000 !important;

  .modal-content {
    min-width: 675px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: var(--spacing-32) var(--spacing-64);
    border-radius: var(--radius-4);
    background-color: var(--primary-0) !important;
    box-shadow: 4px 4px 4px 0 var(--dark-4) !important;
    gap: var(--spacing-32);
    .modal-header {
      border: none;
      border-radius: 0;
    }
    .modal-footer {
      border: none;
      .modal-btns {
        width: 47%;
        &.danger {
          button {
            background-color: var(--alert-140) !important;
            border-color: var(--alert-140) !important;
          }
        }
      }
    }
  }
}

.modal-slide .shipping-box .accordion:not(.border-required) .accordion-item .accordion-body {
  margin-bottom: 0;
}
.modal-slide .shipping-box .accordion:not(.border-required) .accordion-item:first-of-type {
  border-top: 0 !important;
  border-bottom: 0 !important;
}
.modal-slide .shipping-box .accordion:not(.border-required) .accordion-item {
  border-bottom: 0 !important;
}
.setup-rate {
  .accordion:not(.border-required) {
    background-color: var(--primary-0) !important;
    --bs-accordion-border-width: 0;
    --bs-accordion-border-radius: 0 !important;
    width: 100%;
    .accordion-item .accordion-body {
      margin-bottom: 0;
      padding: 0;
    }
    .accordion-item:first-of-type {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
    .accordion-item {
      border-bottom: 0 !important;
      .accordion-button {
        border-radius: 0;
        padding: 0;
        background-color: var(--primary-0) !important;
        justify-content: space-between;
        &::after {
          display: none !important;
          width: 0 !important;
        }
      }
    }
  }
}

.modal-slide {
  .segment-accordian .accordion .accordion-item {
    &:first-of-type {
      border: 0 !important;
    }

    .accordion-button {
      width: auto !important;
      align-items: center;
      gap: var(--spacing-8);
      padding: 0;
      .show {
        display: none;
      }
      .hide {
        display: block;
      }
      &.collapsed {
        .show {
          display: block;
        }
        .hide {
          display: none;
        }
      }
    }
  }
  .modal-header {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: var(--spacing-8);
    padding: var(--spacing-16) 24px;
    border-bottom: solid var(--border-width-1) var(--primary-30);
    background-color: var(--primary-0);
  }
  &.slide {
    &::after {
      position: fixed;
      content: '';
      width: 100%;
      height: 100vh;
      opacity: 0.24;
      background-color: var(--primary-100);
      top: 0;
      transition: width 0.9s linear;
      left: 0;
      z-index: 999999;
    }
    .inner-details {
      width: 690px;
      transition: margin-left 0.2s linear;
      height: 100vh;
      max-width: 100%;
      position: fixed;
      z-index: 99999999;
      top: 0;
      right: 0;
      background-color: var(--primary-0);
      border: solid var(--border-width-1) var(--primary-30);
      transition: all 0.3s ease-in 0s;
      border-radius: 0;
      transition: all 0.5s ease;
      .right-sidebar {
        display: none;
      }
      &.model_sidebar {
        width: 1000px;
        .right-sidebar {
          display: block;
        }
      }
      .modal-body {
        .modal-layout {
          padding: var(--spacing-24) var(--spacing-40);
          width: 690px;
          overflow-y: auto;
        }
        // height: 100%;
      }
    }
    .right-sidebar {
      width: 100%;
      max-width: 390px;
    }
  }
  .inner-details {
    width: 0;
    height: 100vh;
    max-width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    right: -71px;
    margin-left: -130px;
    transition: all 0.5s ease;
  }
  .accordian {
    .accordion-content {
      background-color: var(--primary-0) !important;
      border: solid var(--border-width-1) var(--primary-40);
      border-radius: var(--radius-4);
      margin-bottom: var(--spacing-4);
      padding: var(--spacing-16);
      position: relative;
      .dropdown-toggle::after {
        position: absolute;
        top: 32px;
        right: 20px;
        bottom: auto;
      }
    }
  }
  .accordion {
    background-color: var(--primary-0) !important;
    --bs-accordion-border-width: 0;
    --bs-accordion-border-radius: 0 !important;
    .accordion-item {
      border: 0 !important;
      background-color: var(--primary-0) !important;
      border-bottom: solid var(--border-width-1) var(--primary-30) !important;
      &:first-of-type {
        border-top: solid var(--border-width-1) var(--primary-30) !important;
      }
      .accordion-body {
        background-color: var(--primary-0) !important;
        padding: 0;
        margin-bottom: var(--spacing-16);
      }
      .accordion-button {
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        background-color: var(--primary-0) !important;
        &:focus {
          box-shadow: none !important;
          outline: 0;
        }
        &::after {
          background-image: url('/assets/down-arrow.svg') !important;
        }
      }
      .show {
        background-color: var(--primary-0);
      }
    }
  }
}
.zoom-image-section {
  .carousel-control-prev {
    left: -80px;
  }
  .carousel-control-next {
    right: -80px;
  }
}
.sidebar-widget {
  .accordian {
    .accordion-content {
      background-color: var(--primary-0) !important;
      border: solid var(--border-width-1) var(--primary-40);
      border-radius: var(--radius-4);
      margin-bottom: var(--spacing-4);
      padding: var(--spacing-16);
      position: relative;
      .dropdown-toggle::after {
        position: absolute;
        top: 32px;
        right: 20px;
        bottom: auto;
      }
    }
  }
  .accordion {
    background-color: transparent !important;
    --bs-accordion-border-width: 0;
    .accordion-item {
      border: 0 !important;
      background-color: transparent !important;
      border-bottom: none !important;
      &:first-of-type {
        border-top: none !important;
      }
      .accordion-body {
        background-color: var(--primary-0) !important;
        padding: 0;
        margin-bottom: var(--spacing-16);
      }
      .accordion-button {
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent !important;
        padding-bottom: var(--spacing-16) !important;
        padding-top: var(--spacing-16) !important;
        &:focus {
          box-shadow: none !important;
          outline: 0;
        }
        &::after {
          background-image: url('/assets/down-arrow.svg') !important;
        }
      }
      .show {
        background-color: transparent !important;
      }
    }
  }
}

/*--upload pic widget--*/
.upload-img-sec {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: var(--spacing-8);
  padding: var(--spacing-48);
  border-radius: var(--radius-4);
  text-align: center;
  padding-bottom: var(--spacing-16);
  border: dashed 1px var(--primary-40);
  input {
    opacity: 0;
    width: 100%;
  }
}

/*--customer name component--*/
.customer-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-16);
  width: 100%;
  .media {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--spacing-12);
    padding: 0px 0 var(--spacing-16) 0px;
    border-bottom: solid var(--border-width-1) var(--primary-30);
    width: 100%;
    &:last-child {
      padding: 0;
      border: 0;
    }
    .gap {
      gap: var(--spacing-8);
    }
    .media-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: var(--spacing-4);
      padding: 0;
      h4 {
        color: var(--primary-100);
      }
      .contact-info {
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: var(--spacing-4);
        padding: 0;
      }
    }
  }

  .custom-icon {
    width: 32px;
    height: 32px;
    flex-grow: 0;
    border-radius: var(--radius-100);
    background-color: var(--warning-100);
    padding: var(--spacing-4);
    text-align: center;
    color: var(--primary-0);
  }
}
.fixed-sidebarWidget {
  transition: all 0.5s ease;
  height: 100%;
  position: sticky;
  top: 160px;
  right: 0;
  max-width: 390px;
}
.mode {
  .expand_sidebar {
    top: 97px;
  }
  #ce-wrapper {
    padding-top: 97px; //change it in 59px when remove alert
  }
}
.expand_sidebar {
  display: block !important;
  transition: all 0.5s ease;
  height: 100%;
  position: fixed;
  top: 59px;
  right: 0;
  max-width: 390px;
  width: 100%;
  z-index: 99999;
}
.sidebar-widget {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-24);
  border: solid var(--border-width-1) var(--primary-30);
  background-color: var(--primary-20);
  height: 100%;
  .collapse-sidebar {
    flex-grow: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-8);
    padding: var(--spacing-16) var(--spacing-24);
    border-bottom: solid var(--border-width-1) var(--primary-30);
    background-color: var(--primary-20);
    .sidebar-toggle {
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      h1 {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spacing-8);
        padding: 0;
        .key-btn {
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: var(--spacing-8);
          padding: var(--spacing-2) var(--spacing-4);
          border-radius: var(--radius-4);
          background-color: var(--primary-30);
        }
      }
    }
  }
  .detail-section {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: var(--spacing-8);
    padding: 0 var(--spacing-24);
  }

  .add-cust-widget {
    gap: var(--spacing-8);
    padding: var(--spacing-16);
    border-radius: var(--radius-8);
    box-shadow: 4px 4px 4px 0 var(--dark-4);
    background-color: var(--primary-0);
  }
  .sidebar-actions {
    position: sticky;
    bottom: 0px;
    max-width: 390px;
    border-top: solid var(--border-width-1) var(--primary-30);
    background-color: var(--primary-20);
    z-index: 999999;
  }
}

.attribute-list-overlay {
  .attribute {
    width: 100% !important;
  }
}
.grid {
  align-self: stretch;
  height: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-24);
  padding: var(--spacing-24);
  border-radius: var(--radius-4);
  border: solid var(--border-width-1) var(--primary-30);
  background-color: var(--primary-0);
  .customer-activity {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--spacing-4);
    padding: 0;
    .activity-status {
      li {
        display: inline-block;
        margin-right: var(--spacing-4);
        margin-bottom: var(--spacing-4);
      }
    }
  }
  .attribute-detail {
    .attribute {
      width: 100%;
    }
    .more-attributes {
      display: flex;
      align-items: center;
      margin-top: var(--spacing-12);
      position: relative;
      &::before {
        height: 1px;
        content: '';
        position: absolute;
        background-color: var(--primary-100);
        opacity: 0.2;
        width: 53px;
        left: 24px;
      }
      &::after {
        height: 1px;
        content: '';
        position: absolute;
        background-color: var(--primary-100);
        opacity: 0.2;
        width: 53px;
        right: 24px;
      }
      ng-deep::app-link-btn {
        margin: 0 auto;
      }
    }
  }
}

.custom-dropdown {
  .tab-header {
    .nav-tabs {
      width: 100% !important;
    }
  }
}

[overlaypopup] {
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: var(--spacing-4);
}

.accrodian-dropdown {
  .accordion-header {
    width: 100%;
    position: relative;
  }
  .collapse {
    width: 100%;
  }
  .accordion-item {
    border: none !important;
    color: var(--primary-100) !important;
    border-radius: 0 !important;
    background: transparent !important;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: var(--spacing-12);
    border-bottom: solid var(--border-width-1) var(--primary-30) !important;
    padding: var(--spacing-12) 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0 !important;
    }
  }
  .accordion-button {
    font-family: Satoshi;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary-100) !important;
    background: var(--primary-0) !important;
    box-shadow: none !important;
    padding: 0;
    &::after {
      display: none !important;
    }
  }
  .accordion-body {
    padding: 0;
    .accordion-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: var(--spacing-4);
      max-height: 248px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        border-radius: var(--radius-20);
        background-color: var(--primary-0);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: var(--radius-20);
        background-color: var(--primary-50);
        background-clip: content-box;
      }
    }
  }
}
.product-slider {
  .carousel-indicators {
    margin-bottom: -13px !important;
    .active {
      background-color: var(--primary-100) !important;
    }
  }
  .carousel-indicators [data-bs-target] {
    border-top: 0 !important;
    border-bottom: 0 !important;
    background: var(--primary-40);
    width: 8px !important;
    height: 8px !important;
    border-radius: 100%;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: var(--primary-70) !important;
    border-radius: var(--radius-4) !important;
  }
}
.text-avtar {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  border-radius: var(--radius-100);
  background-color: var(--primary-100);
  color: var(--primary-0);
  align-items: center;
  display: flex;
  justify-content: center;
}

.radius-top-left {
  border-top-left-radius: 0px !important;
}
.radius-bottom-left {
  border-bottom-left-radius: 0px !important;
}
.radius-top-right {
  border-top-right-radius: 0px !important;
}
.border-radius {
  border-radius: var(--radius-8);
}
.border-radius-4 {
  border-radius: var(--radius-4);
}

.rounded {
  border: solid var(--border-width-1) var(--primary-30);
}

.border {
  border-top: solid var(--border-width-1) var(--primary-40);
}
.border-30 {
  border-top: solid var(--border-width-1) var(--primary-30);
}
.border-top {
  border-top: solid var(--border-width-1) var(--primary-30) !important;
}
.border-bottom {
  border-bottom: solid var(--border-width-1) var(--primary-30) !important;
}
.profile-dropdown {
  width: 283px;
  height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-16);
  padding: var(--spacing-24);
  box-shadow: 4px 4px 4px 0 rgba(36, 22, 35, 0.04);
  border: solid var(--border-width-1) var(--primary-30);
  background-color: var(--primary-0);
  z-index: 9999;
  .dropdown-items {
    margin: 0;
    padding: 0;
    width: 100%;
    li {
      padding: var(--spacing-16) 0;
      border-bottom: solid var(--border-width-1) var(--primary-30);
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
}
.border-line {
  height: 1px;
  align-self: stretch;
  flex-grow: 0;
  background-color: var(--primary-30);
}
.custom-dropdown {
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-4);
  padding: var(--spacing-12);
  box-shadow: 4px 4px 4px 0 var(--dark-4);
  border: solid var(--border-width-1) var(--primary-30);
  background-color: var(--primary-0);
  width: 100%;
  z-index: 99999;
  ul {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: var(--spacing-4);
    padding: 0;
    li {
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing-12);
      border-radius: var(--radius-4);
      background-color: var(--primary-0);
      white-space: nowrap;

      ::ng-deep &:hover {
        background-color: var(--primary-20) !important;
        border-radius: var(--radius-4) !important;
      }
    }
  }
}
textarea {
  border-radius: var(--radius-4);
  border: solid var(--border-width-1) var(--primary-40);
  box-shadow: 4px 4px 4px 0 var(--dark-4);
  background-color: var(--primary-0);
  color: var(--primary-100);
  padding: var(--spacing-8);
  height: 100px;
  resize: vertical;
  overflow-x: hidden;
  &::placeholder {
    color: var(--primary-30);
    font-size: 14px;
  }
  &:focus {
    background-color: var(--primary-0);
    outline: none;
    border-color: var(--primary-40);
    box-shadow: 4px 4px 4px 0 var(--dark-4);
  }
  &.form-control {
    background-color: var(--primary-0) !important;
    color: var(--primary-100);
    border: solid var(--border-width-1) var(--primary-40);
    box-shadow: 4px 4px 4px 0 var(--dark-4);
    &:focus {
      background-color: var(--primary-0) !important;
      outline: none !important;
      color: var(--primary-100);
      border-color: var(--primary-40) !important;
      box-shadow: 4px 4px 4px 0 var(--dark-4) !important;
    }
  }
}
.leave-comment {
  position: relative;
  width: 100%;
  bottom: 0;
  z-index: 99999;
  padding: var(--spacing-16) 0;
  background-color: var(--primary-0);

  .circle-btn {
    background-color: var(--primary-100);
    border-radius: var(--radius-100);
    position: absolute;
    right: 16px;
    bottom: 32px;
    border: 0;
    width: 32px;
    height: 32px;
    svg {
      path {
        fill: var(--primary-0);
      }
    }
  }
}
.modal-table {
  .custom-table {
    margin-bottom: 0px;
  }
}
.hightlight-name .right-sidebar-active .hightlist-row1 .customer-name {
  color: var(--text-link);
}

.hightlight-tr {
  .custom-table {
    .table-row {
      border-bottom: none;
    }
    .hightlist-row {
      border-bottom: solid var(--border-width-1) var(--success-120) !important;
      border: solid var(--border-width-1) var(--success-120) !important;
      z-index: 99999;
      &.table-row {
        border-bottom: 0;
      }
      &::after {
        position: fixed;
        content: '';
        width: 100%;
        height: 100vh;
        opacity: 0.5;
        background-color: var(--primary-20);
        top: 0;
        transition: width 0.9s linear;
        left: 0;
        z-index: -1;
      }
    }
  }
}

.custom-table {
  // margin-top: 24px;
  margin-bottom: var(--spacing-12);
  .table-row {
    border-bottom: solid var(--border-width-1) var(--primary-30);
    position: relative;
    .checkbox {
      vertical-align: middle;
    }
    .select-checkbox {
      height: 20px;
      display: flex;
    }
    td {
      padding: var(--spacing-24) var(--spacing-16) var(--spacing-24) 0;
      color: var(--primary-100);
      border: 0;
      vertical-align: middle;
      white-space: nowrap;
      &.first-td {
        padding-right: 0;
        width: 21px;
      }
      .table-control {
        display: flex;
        align-items: center;
        gap: var(--spacing-16);
        justify-content: end;
      }
    }
    th {
      padding: var(--spacing-12) var(--spacing-16) var(--spacing-12) 0;
      border: 0;
      background-color: var(--primary-0);
      color: var(--primary-100);
      white-space: nowrap;
      vertical-align: middle;
      text-transform: capitalize;
      .sort {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spacing-8);
        padding: 0;
      }
      &.first-td {
        width: 20px;
      }
    }
  }
  .control-btn {
    position: sticky;
    right: -1px;
  }
  .checkbox-control {
    position: sticky;
    left: 0px;
  }
}
button#Requires\ attention .tab-icon {
  background-color: var(--alert-140) !important;
  border: solid var(--border-width-1) var(--alert-140) !important;
  color: var(--text-white) !important;
}
.table-footer {
  padding: var(--spacing-24) var(--spacing-12);
  border-top: solid var(--border-width-1) var(--primary-40);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-0);
  width: 100%;

  .table-actions {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-24);
    padding: 0;
    color: var(--primary-100);
    ::ng-deep.delete-btn {
      .add-widget {
        color: var(--alert-140);
      }
    }
    .row-count {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacing-8);
      padding: 0;
      .count {
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-2);
        padding: var(--spacing-2) var(--spacing-4);
        border-radius: var(--radius-4);
        background-color: var(--primary-20);
      }
    }
    .counter {
      padding: var(--spacing-4) var(--spacing-8);
      border-radius: var(--radius-4);
      background-color: var(--primary-0);
      &:hover {
        background-color: var(--primary-20);
      }
    }
  }
  .paginations-sec {
    .paginations {
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacing-12);
      padding: 0;
      color: var(--primary-70);
      .current {
        flex-grow: 0;
        width: 48px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-4);
        padding: var(--spacing-8) var(--spacing-12);
        border-radius: var(--radius-4);
        border: solid var(--border-width-1) var(--primary-40);
        background-color: var(--primary-0);
        color: var(--primary-100);
      }
    }
  }
}

.filter-section {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--spacing-12);
  padding: 0;
  input {
    width: 100%;
  }
}
.btn-secondary {
  flex-grow: 0;
  justify-content: center;
  font-family: Satoshi;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: var(--spacing-12) var(--spacing-24);
  border-radius: 4px;
  border: solid 1px var(--primary-40);
  background-color: var(--primary-0);
  color: var(--primary-100);

  &.focus {
    border: solid 1px var(--info-120);
    background-color: var(--primary-0);
    color: var(--primary-100);
  }
  &:active {
    border: solid 1px var(--info-120) !important;
    background-color: var(--primary-0) !important;
    color: var(--primary-100);
  }
  &:disabled {
    border: solid 1px var(--primary-40);
    background-color: var(--primary-30);
    color: var(--primary-60);
  }
  &:hover {
    border: solid 1px var(--primary-40);
    background-color: var(--primary-20);
    color: var(--primary-100);
  }

  &:focus {
    border: solid 1px var(--info-120);
    background-color: var(--primary-0);
  }
  &:active {
    border: solid 1px var(--info-120);
    background-color: var(--primary-0);
  }

  &.btn-medium {
    padding: var(--spacing-12) var(--spacing-24);
  }
  .dot {
    width: 16px;
    height: 16px;
    flex-grow: 0;
    border-radius: 100%;
    background: var(--success-120);
  }
}
.dropdown-menu {
  border: none;
  background: transparent;
  margin-top: var(--spacing-4) !important;
  z-index: 999999999 !important;
}

.notification-card {
  .tab-header {
    width: 100%;
    .nav-tabs {
      width: 100% !important;
    }
  }
}
/*--firefor menu issue--*/
.side-nav li .menu-link .main-menu {
  width: 16px !important;
  height: 16px !important;
}
app-ce-icon svg {
  width: 16px !important;
  height: 16px !important;
}
.md-drppicker {
  font-family: Satoshi !important;
  font-weight: bold;
  &.shown {
    padding: 0;
    box-shadow: 4px 4px 4px 0 var(--dark-4);
    border: solid var(--border-width-1) var(--primary-30);
    background-color: var(--primary-0);
    margin-top: 0px !important;
    .calendar {
      display: grid !important;
      float: none !important;
    }
  }
  .calendar {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-12);
    padding-bottom: 0;
    background-color: var(--primary-0);
    .calendar-time {
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: var(--spacing-12);
      padding: var(--spacing-12) 0;
      padding-bottom: 0;
      .select {
        .select-item {
          color: var(--primary-60) !important;
        }
      }
      &:before {
        position: absolute;
        content: 'Start time';
        top: -2px;
        left: 0;
        float: left;
        font-family: Satoshi;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: justify;
        color: var(--primary-60);
      }
    }
    &.right {
      padding-top: 0;
      .calendar-time {
        &:before {
          position: absolute;
          content: 'End time';
        }
      }
      .calendar-table {
        display: none;
      }
    }
    .calendar-table {
      align-self: stretch;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      padding: 0;
      background-color: var(--primary-0);
      border: none !important;
      margin-bottom: var(--spacing-12);
    }
  }
  th {
    color: var(--primary-50);
    &.month {
      width: auto;
      flex-grow: 0;
      font-family: Satoshi;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--primary-100);
      vertical-align: middle !important;
    }
    span {
      color: var(--primary-50) !important;
    }
    &.next {
      background-image: url('/assets/arrowLeft.svg') !important;
      background-repeat: no-repeat !important;
      background-size: 16px !important;
      background-position: center !important;
      opacity: 1 !important;
      transition: background-color 0.2s ease;
      width: 40px;
      height: 40px;
      flex-grow: 0;
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-8);
      padding: var(--spacing-12) var(--spacing-24) !important;
      border-radius: var(--radius-4) !important;
      border: solid var(--border-width-1) var(--primary-40);
      background-color: var(--primary-0);
      margin-bottom: var(--spacing-12) !important;
      &:hover {
        margin-bottom: 0px;
      }
    }
    &.prev {
      background-image: url('/assets/arrowLeft.svg') !important;
      background-repeat: no-repeat !important;
      background-size: 16px !important;
      background-position: center !important;
      opacity: 1 !important;
      transition: background-color 0.2s ease;
      width: 40px;
      height: 40px;
      flex-grow: 0;
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-8);
      padding: var(--spacing-12) var(--spacing-24) !important;
      border-radius: var(--radius-4) !important;
      border: solid var(--border-width-1) var(--primary-40);
      background-color: var(--primary-0);
      margin-bottom: var(--spacing-12) !important;
      &:hover {
        margin-bottom: 0px;
      }
    }
    &.month {
      margin-bottom: var(--spacing-12) !important;
    }
  }
  td {
    padding: 0 8px !important;
    height: 32px;
    opacity: 1 !important;
    border-radius: var(--radius-4) !important;
    border: none !important;
    background-color: var(--primary-0) !important;
    color: var(--primary-100) !important;
    &.active {
      background-color: var(--info-120) !important;
      color: var(--primary-0) !important;
      span {
        color: var(--primary-0) !important;
      }
    }
    &.off {
      background-color: var(--primary-0) !important;
      color: var(--primary-100) !important;
      span {
        color: var(--primary-50) !important;
      }
    }
    &.in-range {
      background-color: var(--primary-20) !important;
      span {
        color: var(--info-120) !important;
      }
      &.active {
        background-color: var(--info-120) !important;
        color: var(--primary-0) !important;
        span {
          color: var(--primary-0) !important;
        }
      }
    }
    &.today {
      background-color: var(--primary-100) !important;
      border-radius: var(--radius-4) !important;
      span {
        color: var(--primary-0) !important;
      }
      &.start-date {
        border-radius: var(--radius-4) !important;
      }
    }
    span {
      flex-grow: 0;
      font-family: Satoshi;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--primary-100);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &:hover {
      border-radius: var(--radius-4) !important;
    }
    &.end-date {
      border-radius: var(--radius-4) !important;
    }
    &.start-date {
      border-radius: var(--radius-4) !important;
    }
  }
  .ranges ul {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: var(--spacing-12) !important;
    background-color: var(--primary-0);

    li {
      align-self: stretch !important;
      flex-grow: 0 !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-start !important;
      align-items: center !important;
      gap: var(--spacing-12) !important;
      &:hover {
        background-color: var(--primary-20) !important;
      }
      button {
        padding: var(--spacing-12) var(--spacing-24) !important;
        flex-grow: 0;
        text-align: left;
        color: var(--primary-100);
        font-size: 14px;
        font-weight: 700;
        &.active {
          background-color: var(--info-120) !important;
        }
      }
    }
  }
  .buttons_input {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-12);
    padding: var(--spacing-12);
    background-color: var(--primary-0);
    .btn {
      flex-grow: 0;
      justify-content: center;
      text-align: right;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: var(--spacing-8);
      padding: var(--spacing-12) var(--spacing-24);
      border-radius: var(--radius-4);
      border: solid var(--border-width-1) $primary-100;
      background-color: $primary-100;
      color: $primary-0;
      box-shadow: none;
      text-transform: capitalize;
      &:hover {
        border: solid var(--border-width-1) $primary-70;
        background-color: $primary-70;
        color: $primary-0;
      }
      &:focus {
        border: solid var(--border-width-1) var(--info-120);
        background-color: $primary-100;
      }
      &:active {
        border: solid var(--border-width-1) var(--info-120);
        background-color: $primary-100 !important;
        border-color: var(--info-120) !important;
      }
    }
    .btn-default {
      border: solid var(--border-width-1) var(--primary-40) !important;
      background-color: var(--primary-0) !important;
      color: var(--primary-100) !important;
      &:hover {
        border: solid var(--border-width-1) var(--primary-40);
        background-color: var(--primary-20);
        color: var(--primary-100);
      }

      &:focus {
        border: solid var(--border-width-1) var(--info-120);
        background-color: var(--primary-0);
      }
      &:active {
        border: solid var(--border-width-1) var(--info-120);
        background-color: var(--primary-0);
      }
      svg {
        display: none;
      }
    }
  }
}

/*--progress-bar---*/
.progress {
  /*--progress--*/
  width: 64px;
  height: 64px;
  line-height: 50px;
  background: var(--primary-0);
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  z-index: 0;
}
.progress:after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: var(--radius-50);
  border: solid var(--border-width-4) var(--primary-30);
  position: absolute;
  top: 0;
  left: 0;
}

.line-camp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 240px;
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress .progress-left {
  left: 0;
}
.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: var(--primary-0);
  border-width: 4px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right {
  right: 0;
}
.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: var(--radius-50);
  background: var(--primary-0);
  font-size: 14px;
  color: var(--primary-100);
  line-height: normal;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 999;
}

.progress.yellow .progress-bar {
  border-color: var(--info-120);
}
.progress.yellow .progress-left .progress-bar {
  animation: loading-3 1s linear forwards 1.8s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(126deg);
    transform: rotate(126deg);
  }
}
@media only screen and (max-width: 990px) {
  .progress {
    margin-bottom: var(--spacing-16);
  }
}

/*---scroll bar--*/
.leftsidebarScroll {
  &.custom-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0px;
      background-color: var(--primary-0);
    }
    &:hover {
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        border-radius: var(--radius-20);
        background-color: var(--primary-0);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: var(--radius-20);
        background-color: var(--primary-50);
        background-clip: content-box;
      }
    }
  }
}

.bg-height {
  height: 40px;
  display: flex;
  align-items: center;
}
.bg-hover {
  padding-left: var(--spacing-4);
  &:hover {
    background: var(--primary-20);
    border-radius: var(--radius-4);
  }
}

.handlescroll {
  &.custom-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0px;
      background-color: var(--primary-0);
    }
    &:hover {
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        border-radius: var(--radius-20);
        background-color: var(--primary-0);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: var(--radius-20);
        background-color: var(--primary-50);
        background-clip: content-box;
      }
    }
  }
}
.hidden-scroll {
  transition: 0.3s ease-in-out;

  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: var(--radius-20);
    background-color: var(--primary-0);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: var(--radius-20);
    background-color: var(--primary-50);
    background-clip: content-box;
  }
}
.custom-scroll {
  transition: 0.3s ease-in-out;
  &:hover {
    &::-webkit-scrollbar-track {
      background-color: var(--primary-0);
      border: solid var(--border-width-3) var(--primary-0);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-50);
    }
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: var(--radius-20);
    background-color: transparent;
    transition: 0.3s ease-in-out;
    border: solid var(--border-width-3) transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: var(--radius-20);
    background-color: transparent;
    background-clip: content-box;
    transition: 0.3s ease-in-out;
    border: solid var(--border-width-2) transparent;
  }
}

.horizontal-scroll {
  transition: 0.3s ease-in-out;

  &::-webkit-scrollbar {
    height: 8px;
    cursor: move;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: var(--radius-20);
    background-color: var(--primary-0);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: var(--radius-20);
    background-color: var(--primary-50);
    background-clip: content-box;
  }
}
/*---end custom style scroll bar---*/

/*--container width device prespective--*/

@media screen and (max-width: 1512px), screen and (max-height: 768px) {
  .accordian {
    .accordion-content {
      .dropdown-toggle::after {
        right: 10px;
      }
    }
  }
  .modal-slide {
    &.slide {
      .modal-layout {
        width: 550px;
      }
      .right-sidebar {
        max-width: 324px;
      }
    }
  }
  .sidebar-widget {
    .collapse-sidebar {
      padding: var(--spacing-16);
    }
    .detail-section {
      padding: 0 var(--spacing-16);
    }
    .sidebar-actions {
      max-width: 324px;
      padding: var(--spacing-16) 0;
      &.ps-24.pe-24 {
        padding-left: var(--spacing-16) !important;
        padding-right: var(--spacing-16) !important;
      }
    }
  }
  .table-footer {
    .table-actions {
      white-space: nowrap;
    }
  }
  .highcharts-data-label-connector {
    display: none;
  }
  .highcharts-label {
    display: none;
  }
  .highcharts-tooltip {
    display: block;
  }
}

@media (max-width: 1366px) {
  .page-heading-sec {
    height: auto;
  }
}

/*--toast message--*/
.overlay-container {
  .toast-bottom-left {
    bottom: var(--spacing-48);
    left: 65px;
  }

  .toast-container {
    position: fixed !important;
    z-index: 999999999999999 !important;
    pointer-events: inherit !important;
  }
}

/*--media galllery css--*/
#measuring-container {
  display: ruby-text !important;
}
.media-entry-view-video {
  div {
    padding-top: 70% !important;
  }
  iframe {
    width: 100% !important;
    margin: 0 auto !important;
    right: 0;
  }
}
.frame-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
// for inner tables
.inner-tables {
  min-block-size: calc(var(--vh, 1vh) * 100 - 250px);
  .table-section {
    flex-grow: 1;
  }
  .sticky-footer {
    position: sticky;
    bottom: 0;
  }
}

/*--mat calander--*/
/*--mat calander--*/
.mat-calendar-table-header th {
  font-family: Satoshi;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  text-transform: uppercase;
  color: var(--primary-50) !important;
}
.mat-calendar-period-button {
  font-family: Satoshi;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  color: var(--primary-100) !important;
  left: auto;
  right: auto;
  margin: 0 auto;
  width: 100%;
}
.mat-calendar-previous-button,
.mat-calendar-next-button {
  border-radius: 4px !important;
  border: 1px solid var(--primary-40) !important;
  background: var(--primary-0) !important;
  padding: var(--spacing-8) !important;
  width: 32px !important;
  height: 32px !important;
  position: absolute !important;
}
.mat-calendar-previous-button {
  left: 10px;
}
.mat-calendar-next-button {
  right: 10px;
}
.mat-calendar-previous-button::after,
.mat-calendar-next-button::after {
  margin: 10.5px !important;
}
.mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: var(--spacing-16) !important;
  height: var(--spacing-16) !important;
}
.mat-calendar-body-in-range {
  color: var(--info-120) !important;
}
.mat-calendar-body-label {
  display: none;
}
.mat-calendar-body-in-range::before {
  background-color: var(--primary-20) !important;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: var(--primary-0) !important;
}
.mat-calendar-body-cell-content {
  border-radius: var(--spacing-4) !important;
  color: var(--primary-100) !important;
  border: none !important;
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  &.mat-calendar-body-selected {
    background-color: var(--info-120) !important;
    color: #fff !important;
  }
}
.mat-datepicker-popup {
  padding: 0;
  box-shadow: 4px 4px 4px 0 var(--dark-4);
  border: solid var(--border-width-1) var(--primary-30);
  background-color: var(--primary-0);
  margin-top: 0px !important;
  .mat-datepicker-content .mat-calendar {
    height: auto !important;
  }
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background: var(--primary-100) !important;
  color: var(--primary-0);
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-left: 0 !important;
}
.mat-mdc-input-element {
  &.mat-datepicker-input {
    height: 44px;
    align-self: stretch;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-8);
    padding: var(--spacing-12) var(--spacing-16);
    padding-left: var(--spacing-40);
    border-radius: var(--radius-4);
    border: solid var(--border-width-1) var(--primary-40) !important;
    background-color: var(--primary-0) !important;
    color: var(--primary-60) !important;
    &:hover {
      border: solid var(--border-width-1) var(--primary-40) !important;
      background-color: var(--primary-20) !important;
    }
    &:focus {
      border: solid var(--border-width-1) var(--info-120) !important;
      background-color: var(--primary-0) !important;
      box-shadow: none;
    }
  }
}
.mat-mdc-form-field-icon-suffix {
  position: absolute !important;
  right: 0 !important;
  top: 3px !important;
  .mat-mdc-icon-button svg {
    color: var(--primary-50) !important;
  }
}
.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}
.mdc-line-ripple {
  display: none !important;
}
::ng-deep .mat-form-field-appearance-outline.mat-form-field-disabled,
::ng-deep .mat-form-field-outline {
  opacity: 0;
}
.search-products {
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
}
// for otp input
.otp-input {
  border-radius: 4px !important;
  border: var(--border-width-1) solid var(--primary-50) !important;
  text-align: center !important;
  width: var(--spacing-48) !important;
  height: var(--spacing-48) !important;
  color: var(--primary-100) !important;
  font-size: var(--spacing-16) !important;
  background-color: var(--primary-0) !important;
  &:focus {
    border-color: var(--info-120) !important;
  }
  &:hover {
    border-color: var(--primary-40) !important;
    background-color: var(--primary-20) !important;
  }
}

// mat dropdown
.mat-mdc-autocomplete-panel {
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 250px !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: solid var(--border-width-1) var(--primary-30) !important;
  background-color: var(--primary-0) !important;
  border-bottom-left-radius: var(--radius-4) !important;
  border-bottom-right-radius: var(--radius-4) !important;
  width: 100% !important;
  min-width: max-content !important;
  z-index: 999999;
  padding: var(--spacing-12) !important;
  box-shadow: 4px 4px 4px 0 var(--dark-4) !important;
  .mat-mdc-option {
    min-height: auto !important;
    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
      color: var(--success-120) !important;
    }
  }
}
.mat-mdc-form-field-input-control {
  height: 44px !important;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-8);
  padding: var(--spacing-12) var(--spacing-16) !important;
  border-radius: var(--radius-4) !important;
  border: solid var(--border-width-1) var(--primary-40) !important;
  background-color: var(--primary-0) !important;
  color: var(--primary-70) !important;
  font-size: 14px !important;
  &::placeholder {
    color: var(--primary-50) !important;
  }
}
.mdc-text-field {
  padding: 0 !important;
}
.mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-form-field-infix {
  width: 100% !important;
  .form-control:focus {
    border-color: var(--info-120) !important;
    box-shadow: none !important;
  }
}
.mat-mdc-option.mdc-list-item {
  align-items: center;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  padding: var(--spacing-8) var(--spacing-12);
  border-radius: var(--radius-4);
  white-space: nowrap;
  width: 100%;
  background-color: transparent;
  border: 0;
  color: var(--primary-60);
  &:hover {
    background-color: var(--primary-20) !important;
    color: var(--primary-100) !important;
  }
  .mdc-list-item__primary-text {
    font-family: Satoshi !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary-100) !important;
  }
}
.mat-mdc-tooltip-surface {
  max-width: max-content !important;
}
.mat-mdc-tooltip-panel {
  box-shadow: 4px 4px 4px 0 rgba(36, 22, 35, 0.04);
  background-color: var(--primary-100);
  color: var(--primary-0);
  border-radius: var(--radius-4);
  font-family: Satoshi;
  font-size: 14px;
  font-weight: bold;
  width: max-content;
  height: 35px;
  padding: var(--spacing-8) var(--spacing-12);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-4);
  white-space: nowrap;
}

.mat-mdc-form-field-type-mat-select {
  height: 44px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-8);
  padding: var(--spacing-12) var(--spacing-16);
  border-radius: var(--radius-4);
  border: solid var(--border-width-1) var(--primary-40);
  background-color: var(--primary-0);
  color: var(--primary-70);
  .mdc-text-field {
    font-family: Satoshi !important;
    font-size: 14px !important;
    font-weight: bonormalld !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary-100) !important;
  }
}
div.mat-mdc-select-panel {
  border: solid var(--border-width-1) var(--primary-30) !important;
  background-color: var(--primary-0) !important;
  border-bottom-left-radius: var(--radius-4) !important;
  border-bottom-right-radius: var(--radius-4) !important;
  width: 100% !important;
  min-width: max-content !important;
  z-index: 999999;
  padding: var(--spacing-12) !important;
  box-shadow: 4px 4px 4px 0 var(--dark-4) !important;
}
.mat-mdc-autocomplete-panel .mat-mdc-option.mat-dropdown {
  min-height: 40px !important;
  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
  }
}
.custom-chart-tooltip {
  background: var(--primary-100);
  border-radius: var(--spacing-4);
  min-width: 150px;
  .dash-boarder {
    border: 0.1px solid #e5e7eb;
    opacity: 0.3;
  }
}
.custom-white-tooltip {
  background: var(--primary-0);
  padding: var(--spacing-12);
  border-radius: var(--radius-4);
  border: solid var(--border-width-1) var(--primary-30);
  background-color: var(--primary-0);
  box-shadow: 0px 0px 11px 0px var(--dark-4);
  color: var(--primary-100);
  min-width: 150px;
  .dash-boarder {
    border: 1px dashed var(--primary-30);
  }
  .text-center {
    text-align: center;
  }
}
.pulse-dot {
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  background-color: #16a34a;
  animation: pulse-animation 2s infinite;
  border-radius: 9999px;
  box-shadow: 0px 0px 4px 0px var(--dark-4);
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}
.tooltipDot {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1px;
}
.tooltipBadge {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: var(--spacing-4) var(--spacing-8);
  height: 24px;
  border-radius: var(--radius-4);
  //width: fit-content;
  &.white {
    background: var(--primary-0) !important;
    color: #262626;
    border: 1px solid var(--primary-30);
    width: 24px;
    height: 24px;
  }
  &.value-decreased {
    background-color: var(--alert-100) !important;
    color: #262626;
    border: var(--alert-100) !important;
  }
  &.value-increased {
    background: var(--success-100) !important;
    color: #262626;
    border: 1px solid var(--success-100);
  }
  &.green {
    background: var(--success-100) !important;
    color: var(--primary-0);
    border: 1px solid var(--success-100);
    width: 24px;
    height: 24px;
  }
}

/*--mat expandable--*/
.modal-slide {
  .accordian {
    .mat-expansion-panel-header:first-of-type {
      border-top: solid var(--border-width-1) var(--primary-30) !important;
      border-bottom: 0 !important;
    }
    .mat-expansion-panel-header {
      border: 0 !important;
      background-color: var(--primary-0) !important;
      border-bottom: solid var(--border-width-1) var(--primary-30) !important;
      padding: var(--spacing-16) 0;
      height: auto;
      .mat-expansion-indicator {
        height: 20px;
        width: 20px;
        background-image: url(/assets/down-arrow.svg) !important;
        background-size: cover;
        border: none;
        border-width: 0 !important;
        content: '';
        svg {
          display: none !important;
        }
      }
    }
    .mat-expansion-panel {
      box-shadow: none !important;
      border-radius: 0 !important;
    }
    .mat-expansion-panel-body {
      padding: 0 0px 6px !important;
    }
    .mat-expansion-panel-spacing {
      margin: 0 !important;
    }
  }
}
.mat-expansion-panel {
  box-shadow: none !important;
  border-radius: 0 !important;
}
.modal-slide .accordian .payment-block .mat-expansion-panel-header:first-of-type {
  border-top: none !important;
}
.payment-block {
  .mat-expansion-panel {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
  }
  .mat-expansion-panel-header {
    height: auto !important;
    padding: 0 !important;
  }
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
  .mat-expansion-indicator {
    display: none !important;
  }
}

.mt-3 {
  margin-top: 1rem !important;
}
.cdk-overlay-backdrop-showing {
  opacity: 0 !important;
}
.cdk-global-scrollblock {
  .cdk-overlay-backdrop-showing {
    opacity: 1 !important;
  }
}
.mat-mdc-tab-body-content {
  display: none !important;
}
.mat-mdc-tab-body-active {
  .mat-mdc-tab-body-content {
    transform: none !important;
    display: block !important;
  }
}
.header-right {
  .box-tab {
    gap: unset !important;
  }
}
.dropdown-items {
  .box-tab {
    gap: unset !important;
  }
}
.box-tab {
  gap: var(--spacing-24);
  .mat-mdc-tab-label-container {
    width: 100%;
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: var(--spacing-4);
    border-radius: var(--radius-4);
    border: solid var(--border-width-1) var(--primary-20);
    background-color: var(--primary-20);
    flex-direction: row;
    .mdc-tab__text-label {
      gap: var(--spacing-4) !important;
    }

    .mdc-tab {
      flex-grow: 1;
      display: flex;
      margin-bottom: 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-4);
      border-radius: var(--radius-4);
      text-align: center;
      color: var(--primary-60);
      background-color: var(--primary-20);
      width: auto !important;
      padding: var(--spacing-8) var(--spacing-16) !important;
      &.active {
        border-radius: var(--radius-4);
        box-shadow: 4px 4px 4px 0 var(--dark-4);
        border: solid var(--border-width-1) var(--primary-40);
        background-color: $primary-0;
        color: var(--primary-100);
        svg {
          path {
            fill: var(--primary-100);
          }
        }
      }
      &.disable {
        pointer-events: none;
      }
      &:hover {
        border-radius: var(--radius-4);
        box-shadow: 4px 4px 4px 0 var(--dark-4);
        border: solid var(--border-width-1) var(--primary-40);
        background-color: $primary-0;
      }
      .tab-count {
        padding: var(--spacing-2) var(--spacing-8);
        border-radius: var(--radius-4);
        background-color: var(--primary-20);
      }
    }
    .mdc-tab-indicator--active {
      border-radius: var(--radius-4);
      box-shadow: 4px 4px 4px 0 var(--dark-4);
      border: solid var(--border-width-1) var(--primary-40);
      background-color: var(--primary-0);
      color: var(--primary-100);
      .mdc-tab-indicator__content--underline {
        display: none !important;
      }
    }
  }
  &.size-large {
    .mdc-tab {
      height: 39px;
      @media (max-width: 1600px) {
        height: 33px;
      }
      .mat-ripple {
        padding: var(--spacing-8) var(--spacing-16);
      }
    }
  }
  &.size-medium {
    .mdc-tab {
      height: 31px;
      @media (max-width: 1600px) {
        height: 25px;
      }
      .mat-ripple {
        padding: var(--spacing-4) var(--spacing-8);
      }
    }
  }
}
.mat-mdc-tab-labels {
  gap: var(--spacing-4);
}
.mdc-tab {
  min-width: auto !important;
}

.table-tab-header {
  height: 43px;
  width: max-content;
  flex-direction: row;
  border-bottom: 1px solid var(--primary-30);
  .mdc-tab__text-label {
    font-family: Satoshi;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-4);
    color: var(--primary-60) !important;
    border: none;
    padding: 0 var(--spacing-24) var(--spacing-16) !important;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    height: 43px;
    .tab-count {
      padding: var(--spacing-2) var(--spacing-8);
      border-radius: var(--radius-4);
      background-color: var(--primary-20);
    }
  }
  .mdc-tab--active .mdc-tab__text-label {
    color: var(--primary-100) !important;
    border: none;
    border-bottom: solid 4px var(--primary-100);
  }
  .mdc-tab-indicator {
    display: none !important;
  }
  .mat-mdc-tab-label-container {
    border: none !important;
  }
  .mdc-tab {
    padding: 0 !important;
    height: auto;
    cursor: pointer !important;
  }
}
/*--mat-dialog--*/
// .mat-mdc-dialog-inner-container{
//   min-width: auto !important;
//   max-width: max-content !important;
//   .mat-mdc-dialog-surface{
//     width: max-content !important;
//   }
// }
.mat-mdc-dialog-content {
  display: block;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
  max-height: 75vh !important;
  min-width: 675px !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center;
  padding: var(--spacing-32) var(--spacing-64) !important;
  border-radius: var(--radius-4) !important;
  background-color: var(--primary-0) !important;
  box-shadow: 4px 4px 4px 0 var(--dark-4) !important;
  gap: var(--spacing-32);
}
.mat-mdc-dialog-surface {
  overflow-y: unset !important;
  background-color: transparent !important;
}
.app-loaded {
  .side-nav {
    .sideMenu {
      li {
        .menu-link {
          .hover-menu {
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
}
.avatar img {
  object-fit: cover;
}
.mat-timepicker-panel {
  background: var(--primary-0) !important;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: var(--primary-0);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: var(--radius-20);
    background-color: transparent;
    transition: 0.3s ease-in-out;
    border: solid var(--border-width-3) transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: var(--radius-20);
    background-color: transparent;
    background-clip: content-box;
    transition: 0.3s ease-in-out;
    border: solid var(--border-width-2) var(--primary-30);
  }
}
/*-- hight charts--*/
.highcharts-axis-labels {
  text {
    fill: var(--primary-100) !important;
  }
}
.highcharts-background {
  fill: transparent !important;
}
.highcharts-tooltip-box {
  fill: transparent !important;
}
.highcharts-legend-item {
  text {
    fill: var(--primary-100) !important;
  }
}
tspan.highcharts-text-outline {
  display: none;
}
.highcharts-data-label {
  text {
    fill: var(--primary-100) !important;
  }
}
.percentage-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-4);
  padding: var(--spacing-4) var(--spacing-8);
  border-radius: var(--radius-4);
  width: fit-content;
}
