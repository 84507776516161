/*--add view layout css--*/
.add-layout {
  background-color: var(--primary-20) !important;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: var(--spacing-24);
  padding: var(--spacing-24) 0;
  .fixed-header {
    position: fixed;
    top: 59px;
    left: 64px;
    right: 0;
    border-bottom: solid var(--border-width-1) var(--primary-30);
    background-color: var(--primary-0);
    z-index: 99;
    max-height: 95px;
    width: calc(100% - 64px);
    .first-header {
      max-height: 80px;
      height: 80px;
      position: relative;
    }
    &.second {
      max-height: 170px;
      height: 170px;
      ~ .full-width {
        margin-top: 125px;
        height: calc(var(--vh, 1vh) * 100 - 178px);
        overflow: auto;
      }
    }
    ~ .full-width {
      margin-top: 36px;
      height: calc(var(--vh, 1vh) * 100 - 178px);
      overflow: auto;
    }
  }
  .second-header {
    border-bottom: solid var(--border-width-1) var(--primary-30);
    border-top: solid var(--border-width-1) var(--primary-30);
    background-color: var(--primary-0);
    height: 90px;
    max-height: 90px;
    ~ .full-width {
      margin-top: 0;
    }
  }

  .full-width {
    width: 100%;
  }
  .summary-column {
    max-width: 395px;
    width: 100%;
    box-shadow: 4px 4px 4px 0 var(--dark-4);
    &.sticky-column {
      position: sticky;
      top: 0;
    }
    &.summary-scroll {
      overflow-y: auto;
      max-height: 750px;
    }
  }
  @media (max-width: 767px) {
    .summary-column {
      width: 100%;
      max-width: 100%;
    }
  }
  .ce-container {
    max-width: 1094px;
    margin: 0 auto;
    width: 100%;
  }
  &.large-layout {
    background-color: var(--primary-0) !important;
    .summary-column {
      box-shadow: none;
    }
    .ce-container {
      max-width: 1648px;
      margin: 0 auto;
      width: 100%;
    }
  }
}

/*--notification view layout css---*/
.notification-view {
  background-color: var(--primary-0) !important;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  padding: var(--spacing-24) 0;
  .summary-column {
    max-width: 395px;
    width: 100%;
    box-shadow: 4px 4px 4px 0 var(--dark-4);
    &.sticky-column {
      position: sticky;
      top: 0;
    }
  }
  .content-sec {
    overflow-y: auto;
    height: calc(var(--vh, 1vh) * 100 - 310px);
  }
  @media (max-width: 767px) {
    .summary-column {
      width: 100%;
      max-width: 100%;
    }
  }
  .ce-container {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
  }
}

.mode {
  .leftSidebar-widget {
    top: 97px !important;
  }
  .toggle-rightside {
    top: 97px !important;
  }
  .add-layout .fixed-header {
    top: 97px !important;
  }
  .sidebar-widget {
    top: 97px !important;
  }
}
/*--detail view layout css--*/
.detail-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-block-size: calc(var(--vh, 1vh) * 100 - 99px);
  transition: padding-inline-start 0.2s ease-in-out;
  will-change: padding-inline-start;
  &.full-layout {
    .layout-page-content {
      &.without-sidebar {
        padding-inline-end: 0;
        padding-inline-start: 0;
        .ce-container {
          max-width: 100%;
        }
      }
    }
  }
  .tab-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-block-size: calc(var(--vh, 1vh) * 100 - 250px);
    transition: padding-inline-start 0.2s ease-in-out;
    will-change: padding-inline-start;
    .tab-pane {
      flex-grow: 1;
    }
  }
  .setting-sec {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-block-size: calc(var(--vh, 1vh) * 100 - 220px);
    transition: padding-inline-start 0.2s ease-in-out;
    will-change: padding-inline-start;
    .tab-pane {
      flex-grow: 1;
    }
  }
  .leftSidebar-widget {
    position: fixed;
    top: 59px; //change it in 59px when remove alert
    padding-top: var(--spacing-16);
    transition:
      transform 0.25s ease-in-out,
      inline-size 0.25s ease-in-out,
      box-shadow 0.25s ease-in-out;
    will-change: transform, inline-size;
    z-index: 9999;
    width: 306px;
    &:hover {
      .toggle-leftside {
        display: block !important;
      }
    }
    .detail-section {
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      gap: var(--spacing-8);
      padding: 0;
      padding-bottom: var(--spacing-24);
      margin-bottom: var(--spacing-24);
      height: calc(var(--vh, 1vh) * 100 - 90px);
    }
    .widgets {
      &.edit-widgets {
        &.grid {
          border-color: var(--info-120);
        }
      }
    }
    @media (max-width: 1600px) {
      .widgets {
        min-width: 266px;
        &.edit-widgets {
          &.grid {
            border-color: var(--info-120);
          }
        }
      }
    }
  }
  /* Left Sidebar */
  .hide_leftbar {
    .leftSidebar-widget {
      .toggle-leftside {
        display: block !important;

        .toggle-icon {
          margin-right: 0px;

          svg {
            transform: rotate(0deg);
            path {
              fill: var(--primary-0);
            }
          }
        }
      }
    }
  }
  /* Toggle Left Side */
  .toggle-leftside {
    position: absolute;
    cursor: pointer;
    top: 24px;
    right: -20px;

    .toggle-icon {
      height: 24px;
      width: 24px;
      background-color: var(--primary-100);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--radius-4);
      margin-top: -13px;
      margin-right: 13px;
      transform: translateX(0);
      transition: transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

      svg {
        transform: rotate(180deg);
        path {
          fill: var(--primary-0);
        }
      }
    }
  }
  .toggle-rightside {
    width: 65px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-24);
    padding: 0;
    border-left: solid var(--border-width-1) var(--primary-30);
    border-right: solid var(--border-width-1) var(--primary-30);
    background-color: var(--primary-20);
    height: 100%;
    position: fixed;
    right: 0;
    top: 59px; //change it in 59px when remove alert
    .toggle-menu li {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-8);
      padding: var(--spacing-24);
      border-bottom: solid var(--border-width-1) var(--primary-30);
      cursor: pointer;
    }
  }
  .hide_leftbar {
    .leftSidebar-widget {
      transform: translate(-322px) !important;
      transition:
        transform 0.25s ease-in-out,
        inline-size 0.25s ease-in-out,
        box-shadow 0.25s ease-in-out;
      will-change: transform, inline-size;
    }
  }

  .layout-page-content {
    inline-size: 100%;
    margin-inline: auto;
    flex-grow: 1;
    &.without-sidebar {
      padding-inline-end: 168px;
      padding-inline-start: 103px;
      transition: all 0.5s ease;
      &.right-sidbar {
        padding-inline-end: 493px;
        padding-inline-start: 103px;
        transition: all 0.5s ease;
      }
      &.left-sidebar {
        padding-inline-end: 168px;
        padding-inline-start: 103px;
        transition: all 0.5s ease;
        &.hide-rightSidebar {
          padding-inline-end: 168px;
        }
      }
    }
    &.right-sidbar {
      padding-inline-end: 493px;
      padding-inline-start: 425px;
      transition: all 0.5s ease;
    }
    &.left-sidebar {
      padding-inline-start: 425px;
      padding-inline-end: 168px;
      transition: all 0.5s ease;
      &.hide-rightSidebar {
        padding-inline-end: 103px;
      }
    }
    @media (max-width: 1600px) {
      &.without-sidebar {
        padding-inline-end: 168px;
        padding-inline-start: 103px;
        transition: all 0.5s ease;
        &.right-sidbar {
          padding-inline-end: 394px;
          padding-inline-start: 70px;
          transition: all 0.5s ease;
        }
        &.left-sidebar {
          padding-inline-end: 135px;
          padding-inline-start: 70px;
          transition: all 0.5s ease;
          &.hide-rightSidebar {
            padding-inline-end: 70px;
          }
        }
      }
      &.right-sidbar {
        padding-inline-end: 394px;
        padding-inline-start: 350px;
        transition: all 0.5s ease;
      }
      &.left-sidebar {
        padding-inline-start: 350px;
        padding-inline-end: 135px;
        transition: all 0.5s ease;
        &.hide-rightSidebar {
          padding-inline-end: 70px;
        }
      }
    }
    @media (max-width: 1440px) {
      &.without-sidebar {
        padding-inline-end: 168px;
        padding-inline-start: 103px;
        transition: all 0.5s ease;
        &.right-sidbar {
          padding-inline-end: 135px;
          padding-inline-start: 70px;
          transition: all 0.5s ease;
        }
        &.left-sidebar {
          padding-inline-end: 135px;
          padding-inline-start: 70px;
          transition: all 0.5s ease;
        }
      }
      &.right-sidbar {
        padding-inline-end: 135px;
        padding-inline-start: 350px;
        transition: all 0.5s ease;
      }
      &.left-sidebar {
        padding-inline-start: 350px;
        padding-inline-end: 135px;
        transition: all 0.5s ease;
      }
    }
  }
  .layout-footer {
    inline-size: 100%;
    margin-inline: auto;
    &.sticky-footer {
      position: sticky;
      bottom: 0;
    }
  }
  .right-sidebar-active {
    padding-inline-end: 390px;
    will-change: padding-inline-end;
  }
  @media (max-width: 1600px) {
    .expand_sidebar {
      max-width: 324px;
    }
    .attribute {
      max-width: 324px;
    }
    .leftSidebar-widget {
      max-width: 266px;
    }
    .hide_leftbar {
      .leftSidebar-widget {
        transform: translate(-282px) !important;
        transition:
          transform 0.25s ease-in-out,
          inline-size 0.25s ease-in-out,
          box-shadow 0.25s ease-in-out;
        will-change: transform, inline-size;
      }
    }
    .modal-slide {
      &.slide {
        .right-sidebar {
          max-width: 324px;
        }
      }
    }
  }

  @media (min-width: 1440px) and (max-width: 1820px) {
    .layout-page-content {
      &.right-sidbar {
        .ce-col-lg-3 {
          flex: 0 0 50%;
          max-width: 50%;
        }
        .ce-col-lg-3.ce-col-md-6.ce-col-sm-12.ce-col-12.product-history {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .ce-col-lg-6 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .ce-col-lg-9.ce-col-md-6.ce-col-sm-12.ce-col-12.order-history {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .ce-col-lg-9 {
          flex: 0 0 75%;
          max-width: 75%;
        }
      }
    }
    .small-grid {
      padding: 16px;
    }
  }
  @media (min-width: 1440px) and (max-width: 1600px) {
    .layout-page-content {
      &.without-sidebar {
        .ce-col-lg-3 {
          flex: 0 0 25%;
          max-width: 25%;
        }
        .ce-col-lg-6 {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
    .small-grid {
      padding: var(--spacing-16);
    }
  }
  @media screen and (max-width: 1440px) {
    .sidebar-overlay {
      z-index: 99999;
      top: 0 !important;
      &::after {
        right: 0;
        position: fixed;
        content: '';
        width: 100%;
        height: 100vh;
        opacity: 0.24;
        background-color: var(--primary-100);
        top: 0;
        transition: width 0.9s linear;
        left: 0;
        z-index: -1;
      }
    }
    .sticky-btn {
      bottom: 0px !important;
    }
  }
  @media (min-width: 1440px) and (max-width: 1600px) {
    .right-sidbar {
      .page-heading-sec {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
      }
    }
  }
}
/*--list view layout css--*/
.list-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-block-size: calc(var(--vh, 1vh) * 100 - 99px);
  transition: padding-inline-start 0.2s ease-in-out;
  will-change: padding-inline-start;

  .layout-page-content {
    inline-size: 100%;
    margin-inline: auto;
    flex-grow: 1;
  }
  .layout-footer {
    inline-size: 100%;
    margin-inline: auto;
    &.sticky-footer {
      position: sticky;
      bottom: 0;
    }
  }
  .right-sidebar-active {
    padding-inline-end: 390px;
    will-change: padding-inline-end;
  }
  @media (max-width: 1600px) {
    .sidebar-widget {
      .widgets {
        min-width: 292px;
      }
    }
    .expand_sidebar {
      width: 324px;
    }
    .right-sidebar-active {
      padding-inline-end: 324px;
      will-change: padding-inline-end;
    }
  }
}

/*--modal layout--*/
.modalpage-layout {
  #ce-wrapper {
    padding-top: 0;
    padding-left: 0;
    gap: 0;
  }
  header {
    display: none;
  }
  .main-nav {
    display: none;
  }
  .onboarding-flow {
    background-color: var(--primary-20) !important;
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
    text-align: center;
    .onboarding-grid {
      display: inline-table;
    }
  }
  #tsparticles {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .carousel-inner {
    position: initial;
  }
  .carousel-indicator {
    height: 110px;
    background-color: var(--primary-20);
    bottom: 0px;
    margin: auto;
    right: auto;
    left: auto;
    display: flex;
    z-index: 999999;
    position: fixed;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-16);
    .control-type {
      position: absolute;
      top: 20px;
      color: var(--primary-100);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 214px;
    }
  }

  .carousel-indicator {
    width: 100%;
    button {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: 214px;
      height: 8px;
      padding: 0;
      text-indent: 0px;
      cursor: pointer;
      background-color: var(--primary-30);
      background-clip: padding-box;
      border-radius: 1px;
      margin-left: 0;
      margin-right: 0;
      opacity: 1;
      border: 0 !important;
      border-top: 0 solid transparent;
      border-bottom: 0 solid transparent;
      .selection-value {
        display: none;
        position: absolute;
        bottom: 22px;
        color: var(--primary-100);
        align-items: center;
        justify-content: center;
        width: 214px;
      }
    }
    .active {
      background-color: var(--primary-70);
    }
  }
  .carousel-control-next-icon {
    display: none;
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: fit-content;
    left: 50%;
    right: 50%;
    top: -35px;
    z-index: 99;
    position: absolute;
    padding: 10px auto;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    bottom: auto;
    width: 100%;
    opacity: 1;
  }

  .pt-20 {
    padding-top: 20px !important;
  }
  .block-item {
    width: 100%;
    position: absolute;
    z-index: 0;
    margin-top: 120px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:
      transform 600ms ease 0s,
      opacity 300ms ease 0s;

    &.pre-item {
      opacity: 0;
      transition:
        transform 600ms ease 0s,
        opacity 300ms ease 0s;
      transform: translateY(-100vh);
    }

    &.next-item {
      opacity: 0;
      transition:
        transform 600ms ease 0s,
        opacity 300ms ease 0s;
      transform: translateY(100vh);
    }

    &.active-item {
      transition:
        transform 600ms ease 0s,
        opacity 300ms ease 0s;
    }

    .onboarding-sec {
      max-width: 675px;
      width: 100%;
      position: absolute;
      width: 100%;
      height: 100%;
      padding-top: 0px;
      z-index: 9;
    }
  }

  .onboarding-card {
    padding: 40px 55px;
    align-self: stretch;
    height: auto;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--spacing-16);
    border-radius: 4px;
    border: solid 1px var(--primary-30);
    background-color: var(--primary-0);
    box-shadow: 4px 4px 4px 0 var(--dark-4);
  }

  .onboarding-grid {
    padding: 40px 55px;
    align-self: stretch;
    height: 100%;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--spacing-16);
    border-radius: 4px;
    border: solid 1px var(--primary-30);
    background-color: var(--primary-0);
    box-shadow: 4px 4px 4px 0 var(--dark-4);
    .grid-heading {
      svg {
        width: 48px;
        // height: 48px;
      }
      .h1-headers {
        color: var(--primary-100);
      }
    }
  }
  #setup-organization {
    .btn-secondary {
      flex-grow: 0;
      justify-content: center;
      font-family: Satoshi;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-8);
      padding: 12px 24px;
      border-radius: 4px;
      border: solid 1px var(--primary-40);
      background-color: var(--primary-0);
      color: var(--primary-100);

      &.focus {
        border: solid 1px var(--info-120);
        background-color: var(--primary-0);
        color: var(--primary-100);
      }
      &:disabled {
        border: solid 1px var(--primary-40);
        background-color: var(--primary-30);
        color: var(--primary-60);
      }
      &:hover {
        border: solid 1px var(--primary-40);
        background-color: var(--primary-20);
        color: var(--primary-100);
      }

      &:focus {
        border: solid 1px var(--info-120);
        background-color: var(--primary-0);
      }
      &:active {
        border: solid 1px var(--info-120);
        background-color: var(--primary-0);
      }

      &.btn-medium {
        padding: 12px 24px;
      }
      .dot {
        width: 16px;
        height: 16px;
        flex-grow: 0;
        border-radius: 100%;
        background: var(--success-120);
      }
      &.success-button {
        background-color: var(--success-60);
        border: 1px solid var(--success-120);
        svg {
          path {
            fill: var(--success-120);
          }
        }
      }
    }
    .sticky-btn {
      position: sticky;
      bottom: -24px;
      padding-bottom: 24px;
      background-color: var(--primary-0);
    }
  }

  ::ng-deep {
    // &.main-nav {
    //   display: none;
    // }
    // &header {
    //   display: none;
    // }
    &#ce-wrapper {
      padding-left: 0px;
    }
  }

  .add-layout {
    .loader-btn {
      min-width: 92px;
    }
    .loader-spin {
      width: 14px;
      height: 14px;
      svg {
        display: flex;
        -webkit-animation: fa-spin 2s infinite linear;
        animation: fa-spin 2s infinite linear;
      }
    }
    .gst-verified {
      .verify-input {
        ::ng-deep.classic-input {
          label {
            display: none;
          }
        }
      }
    }
    .answer-type {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: var(--spacing-12);
      width: 100%;
    }

    .answer-type label,
    .answer-type input {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      transition-duration: 0.1s;
      transition-property: background-color, color, border-color, opacity, box-shadow;
      transition-timing-function: ease-out;
    }

    .answer-type input[type='radio'] {
      opacity: 0.01;
      z-index: 100;
    }

    .answer-type input[type='radio']:checked + label,
    .Checked + label {
      background-color: var(--success-60);
      border: 1px solid var(--success-120);
      transition-duration: 0.2s;
      transition-property: background-color, color, border-color, opacity, box-shadow;
      transition-timing-function: ease-out;
      animation: 0.25s ease 0s 2 normal none running blink;
    }
    @keyframes blink {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .answer-type label {
      // background-color: var(--primary-0);
      // border: 1px solid var(--primary-30);
      border-radius: 4px;
      padding: 16px 24px;
      cursor: pointer;
      z-index: 90;
      display: flex;
      align-items: center;
      color: var(--primary-100);
    }
  }
  .onboarding-scroll {
    width: 100%;
  }
  .m-domain-name {
    display: none;
  }
  @media screen and (min-width: 1366px) {
    .info-toast {
      padding-top: 0 !important;
    }
    .onboarding-card,
    .onboarding-grid {
      max-height: 400px;
    }
    .weldone {
      .onboarding-grid {
        max-height: 80vh;
      }
    }
  }
  @media screen and (min-width: 1440px) {
    .info-toast {
      padding-top: 0 !important;
    }
    .onboarding-card,
    .onboarding-grid {
      max-height: 543px;
    }
    .weldone {
      .onboarding-grid {
        max-height: 85vh;
      }
    }
  }
  @media screen and (min-width: 1512px) {
    .info-toast {
      padding-top: 0 !important;
    }
    .onboarding-card,
    .onboarding-grid {
      max-height: 543px;
    }
    .weldone {
      .onboarding-grid {
        max-height: 85vh;
      }
    }
  }
  @media screen and (min-width: 2560px) {
    .onboarding-card,
    .onboarding-grid {
      max-height: 1050px;
    }
  }

  @media screen and (max-width: 1920px) {
    .onboarding-card,
    .onboarding-grid {
      max-height: 700px;
      overflow-y: auto;
    }
  }

  @media screen and (max-width: 800px) {
    .onboarding-header {
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }
    .add-layout {
      .h1-headers {
        font-size: 25px;
      }
      .onboarding-grid {
        margin: 0 15px;
      }
      .onboarding-card {
        margin: 0 15px;
      }
    }
  }
  @media only screen and (max-width: 540px) and (max-height: 640px) {
    .onboarding-card {
      max-height: 450px !important;
    }
  }
  @media only screen and (max-width: 412px) and (max-height: 800px) {
    .onboarding-card {
      max-height: 500px !important;
    }
  }
  @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    .onboarding-card {
      max-height: 660px !important;
    }
  }
  @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    .onboarding-card {
      max-height: 660px !important;
    }
  }
  /*iPhone 14 Pro*/
  @media only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {
    .onboarding-card {
      max-height: 660px !important;
    }
  }
  @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
    .onboarding-card {
      max-height: 600px !important;
    }
  }

  @media screen and (max-width: 540px) {
    .carousel-indicator {
      &.onboarding-footer {
        position: fixed;
        top: 30px;
        height: fit-content;
        align-items: start;
        justify-content: inherit;
        bottom: auto;
        right: 15px;
        left: unset;
        width: fit-content;
        gap: var(--spacing-4);

        button {
          width: 20px;
          height: 4px;
          .control-type {
            display: none;
          }
          .selection-value {
            display: none !important;
          }
        }
      }
    }
    .onboarding-flow {
      background-color: var(--primary-0) !important;
    }
    .domain-name {
      display: none;
    }
    .header-shadow {
      box-shadow: 4px 4px 4px 0 var(--dark-4);
      border-bottom: 1px solid var(--primary-30);
    }
    .onboarding-header {
      padding: 0;
      background-color: var(--primary-0);
      position: sticky;
      top: 0;
      z-index: 999999;
      height: auto;
      .top-nav {
        justify-content: center;
        padding: 15px 0;
      }
      .header-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-8);
        .breadcrumb-navigation {
          h3 {
            font-size: 12px;
            span {
              font-size: 12px;
            }
          }
        }
        .m-domain-name {
          display: block;
          background-color: var(--primary-20);
          border: 1px solid var(--primary-30);
          border-radius: 4px;
          padding: 4px 8px;
        }
      }
    }
    .add-layout {
      .mobile-block {
        display: flex !important;
      }
      .grid-heading {
        p {
          br {
            display: none;
          }
        }
      }
      .block-item {
        margin-top: 0;
        &.build-app {
          .onboarding-card {
            height: 100%;
            .build-heading {
              height: 100%;
            }
          }
        }
      }
      .m-domain-name {
        display: block;
        background-color: var(--primary-20);
        border: 1px solid var(--primary-30);
        border-radius: 4px;
        padding: 4px 8px;
      }
      .h1-headers {
        font-size: 16px;
      }
      .onboarding-grid,
      .onboarding-card {
        max-height: 500px;
        .h3-reg {
          font-weight: 400;
        }
        margin: 0;
        padding: 15px 16px;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
      .actions {
        position: fixed;
        bottom: 0;
        padding: 0 16px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: var(--spacing-4);
        z-index: 9999;
        background: var(--primary-0);
        padding-bottom: 27px;
        .action-btn {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .colored-box {
    display: none !important;
  }
}
.ce-container {
  width: 100%;
  padding: var(--spacing-16);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 960px) {
  .ce-container {
    max-width: 900px;
  }
}

@media (min-width: 1280px) {
  .ce-container {
    max-width: 1200px;
  }
}
@media (min-width: 1440px) {
  .ce-container {
    max-width: 1232px;
  }
}
@media (min-width: 1512px) {
  .ce-container {
    max-width: 1300px;
  }
}

@media (min-width: 1920px) {
  .list-view {
    .ce-container {
      max-width: 1648px;
    }
  }
  .ce-container {
    max-width: 1800px;
  }
}

@media (min-width: 2560px) {
  .list-view {
    .ce-container {
      max-width: 2079px;
    }
  }
}

.ce-container--fluid {
  max-width: 100%;
}

.ce-row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: -12px;
}

.ce-row + .row {
  margin-top: var(--spacing-12);
}

.ce-row + .row--dense {
  margin-top: var(--spacing-4);
}

.ce-row--dense {
  margin: -var(--spacing-4);
}

.ce-row--dense > .ce-col,
.ce-row--dense > [class*='ce-col-'] {
  padding: var(--spacing-4);
}

.ce-row.ce-row--no-gutters {
  margin: 0;
}

.ce-row.ce-row--no-gutters > .ce-col,
.ce-row.ce-row--no-gutters > [class*='ce-col-'] {
  padding: 0;
}

.ce-col-xxl,
.ce-col-xxl-auto,
.ce-col-xxl-12,
.ce-col-xxl-11,
.ce-col-xxl-10,
.ce-col-xxl-9,
.ce-col-xxl-8,
.ce-col-xxl-7,
.ce-col-xxl-6,
.ce-col-xxl-5,
.ce-col-xxl-4,
.ce-col-xxl-3,
.ce-col-xxl-2,
.ce-col-xxl-1,
.ce-col-xl,
.ce-col-xl-auto,
.ce-col-xl-12,
.ce-col-xl-11,
.ce-col-xl-10,
.ce-col-xl-9,
.ce-col-xl-8,
.ce-col-xl-7,
.ce-col-xl-6,
.ce-col-xl-5,
.ce-col-xl-4,
.ce-col-xl-3,
.ce-col-xl-2,
.ce-col-xl-1,
.ce-col-lg,
.ce-col-lg-auto,
.ce-col-lg-12,
.ce-col-lg-11,
.ce-col-lg-10,
.ce-col-lg-9,
.ce-col-lg-8,
.ce-col-lg-7,
.ce-col-lg-6,
.ce-col-lg-5,
.ce-col-lg-4,
.ce-col-lg-3,
.ce-col-lg-2,
.ce-col-lg-1,
.ce-col-md,
.ce-col-md-auto,
.ce-col-md-12,
.ce-col-md-11,
.ce-col-md-10,
.ce-col-md-9,
.ce-col-md-8,
.ce-col-md-7,
.ce-col-md-6,
.ce-col-md-5,
.ce-col-md-4,
.ce-col-md-3,
.ce-col-md-2,
.ce-col-md-1,
.ce-col-sm,
.ce-col-sm-auto,
.ce-col-sm-12,
.ce-col-sm-11,
.ce-col-sm-10,
.ce-col-sm-9,
.ce-col-sm-8,
.ce-col-sm-7,
.ce-col-sm-6,
.ce-col-sm-5,
.ce-col-sm-4,
.ce-col-sm-3,
.ce-col-sm-2,
.ce-col-sm-1,
.ce-col,
.ce-col-auto,
.ce-col-12,
.ce-col-11,
.ce-col-10,
.ce-col-9,
.ce-col-8,
.ce-col-7,
.ce-col-6,
.ce-col-5,
.ce-col-4,
.ce-col-3,
.ce-col-2,
.ce-col-1 {
  width: 100%;
  padding: var(--spacing-12);
}

.ce-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.ce-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.ce-col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.ce-col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.ce-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.ce-col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.ce-col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.ce-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.ce-col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.ce-col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.ce-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.ce-col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.ce-col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.ce-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-1.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-1 {
  margin-left: 8.3333333333%;
}

.offset-1.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-1 {
  margin-right: 8.3333333333%;
}

.offset-2.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-2 {
  margin-left: 16.6666666667%;
}

.offset-2.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-2 {
  margin-right: 16.6666666667%;
}

.offset-3.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-3 {
  margin-left: 25%;
}

.offset-3.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-3 {
  margin-right: 25%;
}

.offset-4.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-4 {
  margin-left: 33.3333333333%;
}

.offset-4.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-4 {
  margin-right: 33.3333333333%;
}

.offset-5.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-5 {
  margin-left: 41.6666666667%;
}

.offset-5.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-5 {
  margin-right: 41.6666666667%;
}

.offset-6.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-6 {
  margin-left: 50%;
}

.offset-6.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-6 {
  margin-right: 50%;
}

.offset-7.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-7 {
  margin-left: 58.3333333333%;
}

.offset-7.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-7 {
  margin-right: 58.3333333333%;
}

.offset-8.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-8 {
  margin-left: 66.6666666667%;
}

.offset-8.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-8 {
  margin-right: 66.6666666667%;
}

.offset-9.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-9 {
  margin-left: 75%;
}

.offset-9.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-9 {
  margin-right: 75%;
}

.offset-10.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-10 {
  margin-left: 83.3333333333%;
}

.offset-10.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-10 {
  margin-right: 83.3333333333%;
}

.offset-11.ce-locale--is-ltr,
.ce-locale--is-ltr .offset-11 {
  margin-left: 91.6666666667%;
}

.offset-11.ce-locale--is-rtl,
.ce-locale--is-rtl .offset-11 {
  margin-right: 91.6666666667%;
}

@media (min-width: 600px) {
  .ce-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .ce-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .ce-col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .ce-col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .ce-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ce-col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ce-col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .ce-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ce-col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .ce-col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .ce-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ce-col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .ce-col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .ce-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-sm-0.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-0.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-0 {
    margin-right: 0;
  }

  .offset-sm-1.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-1.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-1 {
    margin-right: 8.3333333333%;
  }

  .offset-sm-2.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-2.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-2 {
    margin-right: 16.6666666667%;
  }

  .offset-sm-3.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-3.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-3 {
    margin-right: 25%;
  }

  .offset-sm-4.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-4.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-4 {
    margin-right: 33.3333333333%;
  }

  .offset-sm-5.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-5.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-5 {
    margin-right: 41.6666666667%;
  }

  .offset-sm-6.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-6.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-6 {
    margin-right: 50%;
  }

  .offset-sm-7.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-7.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-7 {
    margin-right: 58.3333333333%;
  }

  .offset-sm-8.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-8.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-8 {
    margin-right: 66.6666666667%;
  }

  .offset-sm-9.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-9.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-9 {
    margin-right: 75%;
  }

  .offset-sm-10.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-10.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-10 {
    margin-right: 83.3333333333%;
  }

  .offset-sm-11.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .offset-sm-11.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-sm-11 {
    margin-right: 91.6666666667%;
  }
}

@media (min-width: 960px) {
  .ce-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .ce-col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .ce-col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .ce-col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .ce-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ce-col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ce-col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .ce-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ce-col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .ce-col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .ce-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ce-col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .ce-col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .ce-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-md-0.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-0.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-0 {
    margin-right: 0;
  }

  .offset-md-1.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-1.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-1 {
    margin-right: 8.3333333333%;
  }

  .offset-md-2.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-2.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-2 {
    margin-right: 16.6666666667%;
  }

  .offset-md-3.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-3.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-3 {
    margin-right: 25%;
  }

  .offset-md-4.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-4.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-4 {
    margin-right: 33.3333333333%;
  }

  .offset-md-5.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-5.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-5 {
    margin-right: 41.6666666667%;
  }

  .offset-md-6.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-6.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-6 {
    margin-right: 50%;
  }

  .offset-md-7.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-7.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-7 {
    margin-right: 58.3333333333%;
  }

  .offset-md-8.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-8.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-8 {
    margin-right: 66.6666666667%;
  }

  .offset-md-9.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-9.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-9 {
    margin-right: 75%;
  }

  .offset-md-10.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-10.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-10 {
    margin-right: 83.3333333333%;
  }

  .offset-md-11.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .offset-md-11.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-md-11 {
    margin-right: 91.6666666667%;
  }
}

@media (min-width: 1280px) {
  .ce-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .ce-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .ce-col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .ce-col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .ce-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ce-col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ce-col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .ce-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ce-col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .ce-col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .ce-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ce-col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .ce-col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .ce-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-lg-0.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-0.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-0 {
    margin-right: 0;
  }

  .offset-lg-1.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-1.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-1 {
    margin-right: 8.3333333333%;
  }

  .offset-lg-2.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-2.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-2 {
    margin-right: 16.6666666667%;
  }

  .offset-lg-3.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-3.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-3 {
    margin-right: 25%;
  }

  .offset-lg-4.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-4.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-4 {
    margin-right: 33.3333333333%;
  }

  .offset-lg-5.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-5.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-5 {
    margin-right: 41.6666666667%;
  }

  .offset-lg-6.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-6.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-6 {
    margin-right: 50%;
  }

  .offset-lg-7.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-7.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-7 {
    margin-right: 58.3333333333%;
  }

  .offset-lg-8.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-8.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-8 {
    margin-right: 66.6666666667%;
  }

  .offset-lg-9.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-9.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-9 {
    margin-right: 75%;
  }

  .offset-lg-10.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-10.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-10 {
    margin-right: 83.3333333333%;
  }

  .offset-lg-11.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .offset-lg-11.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-lg-11 {
    margin-right: 91.6666666667%;
  }
}

@media (min-width: 1920px) {
  .ce-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .ce-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .ce-col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .ce-col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .ce-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ce-col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ce-col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .ce-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ce-col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .ce-col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .ce-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ce-col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .ce-col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .ce-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-xl-0.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-0.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-0 {
    margin-right: 0;
  }

  .offset-xl-1.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-1.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-1 {
    margin-right: 8.3333333333%;
  }

  .offset-xl-2.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-2.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-2 {
    margin-right: 16.6666666667%;
  }

  .offset-xl-3.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-3.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-3 {
    margin-right: 25%;
  }

  .offset-xl-4.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-4.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-4 {
    margin-right: 33.3333333333%;
  }

  .offset-xl-5.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-5.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-5 {
    margin-right: 41.6666666667%;
  }

  .offset-xl-6.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-6.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-6 {
    margin-right: 50%;
  }

  .offset-xl-7.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-7.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-7 {
    margin-right: 58.3333333333%;
  }

  .offset-xl-8.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-8.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-8 {
    margin-right: 66.6666666667%;
  }

  .offset-xl-9.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-9.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-9 {
    margin-right: 75%;
  }

  .offset-xl-10.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-10.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-10 {
    margin-right: 83.3333333333%;
  }

  .offset-xl-11.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .offset-xl-11.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xl-11 {
    margin-right: 91.6666666667%;
  }
}

@media (min-width: 2560px) {
  .ce-col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .ce-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .ce-col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .ce-col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .ce-col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ce-col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .ce-col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .ce-col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ce-col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .ce-col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .ce-col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ce-col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .ce-col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .ce-col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset-xxl-0.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-0.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-0 {
    margin-right: 0;
  }

  .offset-xxl-1.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-1.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-1 {
    margin-right: 8.3333333333%;
  }

  .offset-xxl-2.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-2.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-2 {
    margin-right: 16.6666666667%;
  }

  .offset-xxl-3.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-3.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-3 {
    margin-right: 25%;
  }

  .offset-xxl-4.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-4.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-4 {
    margin-right: 33.3333333333%;
  }

  .offset-xxl-5.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-5.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-5 {
    margin-right: 41.6666666667%;
  }

  .offset-xxl-6.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-6.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-6 {
    margin-right: 50%;
  }

  .offset-xxl-7.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-7.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-7 {
    margin-right: 58.3333333333%;
  }

  .offset-xxl-8.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-8.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-8 {
    margin-right: 66.6666666667%;
  }

  .offset-xxl-9.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-9.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-9 {
    margin-right: 75%;
  }

  .offset-xxl-10.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-10.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-10 {
    margin-right: 83.3333333333%;
  }

  .offset-xxl-11.ce-locale--is-ltr,
  .ce-locale--is-ltr .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }

  .offset-xxl-11.ce-locale--is-rtl,
  .ce-locale--is-rtl .offset-xxl-11 {
    margin-right: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
