/* Spacing css */

:root {
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-10: 10px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-56: 56px;
  --spacing-64: 64px;
  --spacing-72: 72px;
  --spacing-80: 80px;
  --spacing-96: 96px;
  --spacing-128: 128px;
  --spacing-256: 256px;

  /*--border-radius--*/
  --radius-2: 2px;
  --radius-4: 4px;
  --radius-8: 8px;
  --radius-20: 20px;
  --radius-50: 50%;
  --radius-100: 100%;

  /*--border-width--*/
  --border-width-1: 1px;
  --border-width-2: 2px;
  --border-width-3: 3px;
  --border-width-4: 4px;

  @media screen and (max-width: 1600px) {
    --spacing-2: 1px;
    --spacing-4: 4px;
    --spacing-8: 6px;
    --spacing-12: 10px;
    --spacing-16: 14px;
    --spacing-24: 22px;
    --spacing-32: 30px;
    --spacing-40: 38px;
    --spacing-48: 46px;
    --spacing-56: 54px;
    --spacing-64: 62px;
    --spacing-72: 70px;
    --spacing-80: 78px;
    --spacing-96: 94px;
    --spacing-128: 126px;
    --spacing-256: 254px;
  }
}
$spacing-2: var(--spacing-2);
$spacing-4: var(--spacing-4);
$spacing-8: var(--spacing-8);
$spacing-12: var(--spacing-12);
$spacing-16: var(--spacing-16);
$spacing-24: var(--spacing-24);
$spacing-32: var(--spacing-32);
$spacing-40: var(--spacing-40);
$spacing-48: var(--spacing-48);
$spacing-56: var(--spacing-56);
$spacing-64: var(--spacing-64);
$spacing-72: var(--spacing-72);
$spacing-80: var(--spacing-80);
$spacing-96: var(--spacing-96);
$spacing-128: var(--spacing-128);
$spacing-256: var(--spacing-256);

.spacing-2 {
  gap: $spacing-2;
}
.spacing-4 {
  gap: $spacing-4;
}
.spacing-8 {
  gap: $spacing-8;
}
.spacing-12 {
  gap: $spacing-12 !important;
}
.spacing-16 {
  gap: $spacing-16 !important;
}
.spacing-24 {
  gap: $spacing-24;
}
.spacing-32 {
  gap: $spacing-32;
}
.spacing-40 {
  gap: $spacing-40;
}
.spacing-48 {
  gap: $spacing-48;
}
.spacing-56 {
  gap: $spacing-56;
}
.spacing-64 {
  gap: $spacing-64;
}
.spacing-72 {
  gap: $spacing-72;
}
.spacing-80 {
  gap: $spacing-80;
}
.spacing-96 {
  gap: $spacing-96;
}
.spacing-128 {
  gap: $spacing-128;
}
.spacing-256 {
  gap: $spacing-256;
}

/*----heights--*/
.h-45 {
  height: 45px;
}

.w-80 {
  width: 80%;
}
/*--postions--*/
.bottom-48 {
  bottom: 48px !important;
}

.row > * {
  margin-top: 1.5rem;
}
/*--max-hights--*/
.mx-h-37 {
  max-height: 370px;
}
/*--max-hights--*/
.pt-8 {
  padding-top: var(--spacing-8);
}
.pb-8 {
  padding-bottom: var(--spacing-8);
}
.p-12 {
  padding: var(--spacing-12);
}
.pt-12 {
  padding-top: var(--spacing-12);
}
.pb-12 {
  padding-bottom: var(--spacing-12);
}
.p-16 {
  padding: var(--spacing-16);
}
.pt-16 {
  padding-top: var(--spacing-16);
}
.ps-16 {
  padding-left: var(--spacing-16) !important;
}
.pe-16 {
  padding-right: 16px !important;
}
.ps-12 {
  padding-left: var(--spacing-12) !important;
}
.pe-12 {
  padding-right: 12px !important;
}
.pb-16 {
  padding-bottom: var(--spacing-16);
}
.p-20 {
  padding: 20px;
}
.pb-20 {
  padding-bottom: 20px !important;
}

.ps-24 {
  padding-left: var(--spacing-24);
}
.pe-24 {
  padding-right: var(--spacing-24);
}
.p-24 {
  padding: var(--spacing-24);
}
.pt-20 {
  padding-top: 20px;
}
.pt-24 {
  padding-top: var(--spacing-24);
}
.pb-24 {
  padding-bottom: var(--spacing-24);
}
.pr-32 {
  padding-right: var(--spacing-32);
}
.ps-40 {
  padding-left: 40px;
}
.ps-2 {
  padding-left: var(--spacing-2);
}
.pe-40 {
  padding-right: 40px;
}

.mt-10 {
  margin-top: 10px;
}
.flex-1 {
  flex: 1;
}

.ms-24 {
  margin-left: var(--spacing-24);
}
.me-24 {
  margin-right: var(--spacing-24);
}
.mt-98 {
  margin-top: 98px;
}
.mt-160 {
  margin-top: 160px;
}
.mt-4 {
  margin-top: 4px;
}
.mb-8 {
  margin-bottom: var(--spacing-8);
}
.mt-8 {
  margin-top: var(--spacing-8);
}
.m-16 {
  margin: var(--spacing-16);
}
.mb-12 {
  margin-bottom: var(--spacing-12);
}
.mt-12 {
  margin-top: var(--spacing-12);
}
.mb-24 {
  margin-bottom: var(--spacing-24);
}
.mt-24 {
  margin-top: var(--spacing-24);
}
.p-0 {
  padding: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.p-3 {
  padding: var(--spacing-16) !important;
}
.mb-8 {
  margin-bottom: var(--spacing-8);
}

.ps-3 {
  padding-left: 1rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
