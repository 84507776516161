ce-form-field {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);

  .form-field-label {
    display: flex;
    gap: var(--spacing-8);
  }

  &.label-left {
    flex-direction: row;
    align-items: center;
  }
}

ce-form-field.standard {
  .form-field-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    flex-grow: 1;
  }

  .form-field-input {
    display: flex;
    gap: var(--spacing-8);
    align-items: center;
    border: solid var(--border-width-1) var(--primary-40) !important;
    background-color: var(--primary-0) !important;
    padding: var(--spacing-8);
    border-radius: var(--radius-4);
    min-height: 44px;

    .form-field-suffix {
      display: inline-flex;
      align-items: center;
      height: 100%;
    }

    input,
    textarea {
      border: 0;
      padding: 0;
      background: transparent !important;
      height: 100%;
      flex-grow: 1;
      box-shadow: none !important;
      width: 100%;
      font-family: Satoshi;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    input::placeholder,
    textarea::placeholder {
      color: var(--primary-40);
    }

    /* Select */
    .mat-mdc-select-trigger {
      gap: var(--spacing-8);
      font-weight: bold;
    }

    /* Date picker */
    .mat-datepicker-toggle {
      display: inline-flex;
      align-items: center;
      height: 100%;
      color: var(--primary-50);

      button {
        padding: 0 !important;
        width: 24px;
        height: 100%;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  &:hover {
    .form-field-input {
      background-color: var(--primary-20) !important;
    }
  }

  &.focus {
    .form-field-input {
      border: solid var(--border-width-1) var(--info-120) !important;
      background-color: var(--primary-0) !important;
      box-shadow: none !important;
    }
  }

  &.invalid {
    .form-field-input {
      border: solid var(--border-width-1) var(--alert-140) !important;
      background-color: var(--primary-0) !important;
    }
  }

  ce-form-field-error {
    font-size: 12px;
  }
}

/* Panel */
.group-panel {
  padding: var(--spacing-16);
  background: var(--primary-0);
  border-radius: var(--radius-4);
  border: solid var(--border-width-1) var(--primary-40);
  width: 100%;
  flex-grow: 1;
}
