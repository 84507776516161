.dark-mode {
  background: #141415;
  .docs-story {
    background: #141415;
  }
}
.dark-mode {
  background: var(--primary-0);
  .loading-state {
    h1 {
      color: var(--primary-100) !important;
    }
    h3 {
      color: var(--primary-100) !important;
    }
  }

  .docs-story {
    background: var(--primary-0);
  }

  .form-switch {
    .form-check-input {
      background-image: url('/assets/inactive-dark.svg') !important;
      &:checked {
        background-image: url('/assets/active-dark.svg') !important;
      }
      &:disabled {
        background-image: url('/assets/active-dark.svg') !important;
      }
    }
  }
  .form-check-input {
    &:checked[type='radio'] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23000'/%3e%3c/svg%3e") !important;
    }
    &:checked[type='checkbox'] {
      background-image: url('/assets/check-dark.svg');
    }
    &.checkbox {
      &:indeterminate[type='checkbox'] {
        background-image: url('/assets/intermideate-dark.svg');
      }
      &:disabled {
        background-image: url('/assets/check.svg');
      }
    }
  }
  .status-ended {
    color: var(--primary-100);
  }
  .status-tag {
    color: var(--primary-100);
  }
  .text-black {
    color: var(--primary-100);
  }
}
