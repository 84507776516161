.raycast .cmdk-group,
.raycast .cmdk-list,
.raycast .cmdk-command {
  display: block;
}

.raycast .cmdk-command {
  width: 640px;
  background: var(--primary-0);
  border-radius: 12px;
  padding: var(--spacing-8) 0;
  border: 1px solid var(--primary-30);
  position: relative;
}

.raycast .cmdk-command kbd {
  font-family: var(--font-sans);
  background: var(--gray3);
  color: var(--gray11);
  height: 20px;
  width: 20px;
  border-radius: 4px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow-icon svg {
  height: 12px !important;
  width: 12px !important;
}
.raycast .cmdk-command kbd:first-of-type {
  margin-left: 8px;
}

.raycast .cmdk-input {
  border: none;
  width: 100%;
  padding: 12px 16px;
  outline: none;
  background: var(--primary-0);
  color: var(--primary-50);
  border-bottom: 1px solid var(--primary-40);
}

.raycast .cmdk-input::placeholder {
  color: var(--gray9);
}

.dark .raycast .cmdk-raycast-top-shine {
  background: linear-gradient(
    90deg,
    rgba(56, 189, 248, 0),
    var(--gray5) 20%,
    var(--gray9) 67.19%,
    rgba(236, 72, 153, 0)
  );
  height: 1px;
  position: absolute;
  top: -1px;
  width: 100%;
  z-index: -1;
  opacity: 0;
  animation: showTopShine 0.1s ease forwards 0.2s;
}

.raycast .cmdk-raycast-loader {
  --loader-color: var(--gray9);
  border: 0;
  width: 100%;
  left: 0;
  height: 1px;
  background: var(--gray6);
  position: relative;
  overflow: visible;
  display: block;
  margin-top: 12px;
  margin-bottom: 12px;
}

.raycast .cmdk-raycast-loader:after {
  content: '';
  width: 50%;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, transparent 0%, var(--loader-color) 50%, transparent 100%);
  top: -1px;
  opacity: 0;
  animation-duration: 1.5s;
  animation-delay: 1s;
  animation-timing-function: ease;
  animation-name: loading;
}

.raycast .cmdk-item {
  content-visibility: auto;
  cursor: pointer;
  height: 40px;
  border-radius: var(--spacing-8);
  color: var(--gray12);
  -webkit-user-select: none;
  user-select: none;
  will-change: background, color;
  transition: all 0.15s ease;
  transition-property: none;
  width: 100%;
  background: var(--primary-0);
  border: none;
  &:hover {
    background: var(--primary-20);
    .suggestion-name {
      color: var(--primary-70) !important;
    }
  }
}

.raycast .cmdk-item[aria-selected='true'] {
  background: var(--primary-20);
  color: var(--gray12);
}

.raycast .cmdk-item[aria-disabled='true'] {
  color: var(--gray8);
  cursor: not-allowed;
}

.raycast .cmdk-item:active {
  transition-property: background;
  background: var(--primary-30);
  .suggestion-name {
    color: var(--primary-100);
  }
}

.cmdk-item-active .suggestion-name {
  color: var(--primary-70) !important;
}

.raycast .cmdk-item:focus {
  outline: none;
}

.raycast .cmdk-item:first-child {
  margin-top: 8px;
}

// .raycast .cmdk-item + .cmdk-item {
//   margin-top: 4px;
// }

.raycast .cmdk-item svg {
  width: 18px;
  height: 18px;
}

.raycast .cmdk-raycast-meta {
  margin-left: auto;
  color: var(--gray11);
  font-size: 13px;
}

.raycast .cmdk--list {
  padding: 0 8px 40px;
  height: 500px;
  overflow: auto;
  overscroll-behavior: contain;
  scroll-padding-block-end: 40px;
  transition: 0.1s ease;
  transition-property: height;
}

.raycast .cmdk-raycast-open-trigger,
.raycast .cmdk-raycast-subcommand-trigger {
  color: var(--gray11);
  padding: 0 4px 0 8px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  height: 28px;
  letter-spacing: -0.25px;
}

.raycast .cmdk-raycast-clipboard-icon,
.raycast .cmdk-raycast-hammer-icon {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.raycast .cmdk-raycast-clipboard-icon svg,
.raycast .cmdk-raycast-hammer-icon svg {
  width: 14px;
  height: 14px;
}

.raycast .cmdk-raycast-clipboard-icon {
  background: linear-gradient(to bottom, #f55354, #eb4646);
}

.raycast .cmdk-raycast-hammer-icon {
  background: linear-gradient(to bottom, #6cb9a3, #2c6459);
}

.raycast .cmdk-raycast-open-trigger {
  display: flex;
  align-items: center;
  color: var(--gray12);
  margin-left: auto;
}

.raycast .cmdk-raycast-subcommand-trigger {
  display: flex;
  align-items: center;
  gap: 4px;
  right: 8px;
  bottom: 8px;
}

.raycast .cmdk-raycast-subcommand-trigger svg {
  width: 14px;
  height: 14px;
}

.raycast .cmdk-raycast-subcommand-trigger hr {
  height: 100%;
  background: var(--gray6);
  border: 0;
  width: 1px;
}

.raycast .cmdk-raycast-subcommand-trigger[aria-expanded='true'],
.raycast .cmdk-raycast-subcommand-trigger:hover {
  background: var(--gray4);
}

.raycast .cmdk-raycast-subcommand-trigger[aria-expanded='true'] kbd,
.raycast .cmdk-raycast-subcommand-trigger:hover kbd {
  background: var(--gray7);
}

.raycast .cmdk-separator hr {
  height: 1px;
  width: 100%;
  background: var(--gray5);
  margin: 4px 0;
}

.raycast *:not([hidden]) + .cmdk-group {
  // margin-top: 8px;
}

.raycast .cmdk-group-label {
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  color: var(--gray11);
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.raycast .cmdk-raycast-footer {
  display: flex;
  height: 40px;
  align-items: center;
  width: 100%;
  position: absolute;
  background: var(--gray1);
  bottom: 0;
  padding: 8px;
  border-top: 1px solid var(--gray6);
  border-radius: 0 0 12px 12px;
}

.raycast .cmdk-raycast-footer svg {
  width: 20px;
  height: 20px;
  filter: grayscale(1);
}

.raycast .cmdk-raycast-footer hr {
  height: 12px;
  width: 1px;
  border: 0;
  background: var(--gray6);
  margin: 0 4px 0 12px;
}

@media (prefers-color-scheme: dark) {
  .raycast .cmdk-raycast-footer {
    background: var(--gray2);
  }
}

.raycast .cmdk-raycast-dialog {
  z-index: var(--layer-portal);
  position: fixed;
  left: 50%;
  top: var(--page-top);
  transform: translate(-50%);
}

.raycast .cmdk-raycast-dialog [cmdk] {
  width: 640px;
  transform-origin: center center;
  animation: dialogIn var(--transition-fast) forwards;
}

.raycast .cmdk-raycast-dialog[data-state='closed'] [cmdk] {
  animation: dialogOut var(--transition-fast) forwards;
}

.raycast .cmdk-empty {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  white-space: pre-wrap;
  color: var(--gray11);
}

.raycast .cmdk-list {
  max-height: 500px;
  overflow: auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  scroll-padding-block-end: 40px;
  transition: 0.1s ease;
  transition-property: height;
  padding: 0 var(--spacing-8) 0px;
}

@keyframes loading {
  0% {
    opacity: 0;
    transform: translate(0);
  }

  50% {
    opacity: 1;
    transform: translate(100%);
  }

  to {
    opacity: 0;
    transform: translate(0);
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
    opacity: 0;
  }
}

@keyframes border {
  to {
    box-shadow: 0 0 0 1px var(--gray6);
  }
}

@keyframes showTopShine {
  to {
    opacity: 1;
  }
}

.raycast-submenu {
  animation-duration: 0.2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  transform-origin: var(--radix-popover-content-transform-origin);
}

.raycast-submenu .cmdk-command {
  display: flex;
  flex-direction: column;
  width: 320px;
  border: 1px solid var(--gray6);
  background: var(--gray2);
  border-radius: 8px;
}

.raycast-submenu .cmdk-list {
  padding: 8px;
  overflow: auto;
  overscroll-behavior: contain;
  transition: 0.1s ease;
  transition-property: height;
}

.raycast-submenu .cmdk-item {
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  color: var(--gray12);
  -webkit-user-select: none;
  user-select: none;
  will-change: background, color;
  transition: all 0.15s ease;
  transition-property: none;
  width: 100%;
}

.raycast-submenu .cmdk-item[aria-selected='true'] {
  background: var(--gray5);
  color: var(--gray12);
}

.raycast-submenu .cmdk-item[aria-selected='true'] .cmdk-raycast-submenu-shortcuts kbd {
  background: var(--gray7);
}

.raycast-submenu .cmdk-item[aria-disabled='true'] {
  color: var(--gray8);
  cursor: not-allowed;
}

.raycast-submenu .cmdk-item:focus {
  outline: none;
}

.raycast-submenu .cmdk-item svg {
  width: 16px;
  height: 16px;
}

.raycast-submenu .cmdk-item .cmdk-raycast-submenu-shortcuts {
  display: flex;
  margin-left: auto;
  gap: 2px;
}

.raycast-submenu .cmdk-item .cmdk-raycast-submenu-shortcuts kbd {
  font-family: var(--font-sans);
  background: var(--gray5);
  color: var(--gray11);
  height: 20px;
  width: 20px;
  border-radius: 4px;
  padding: 0 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.raycast-submenu .cmdk-item .cmdk-raycast-submenu-shortcuts kbd:first-of-type {
  margin-left: 8px;
}

.raycast-submenu .cmdk-group-label {
  text-transform: capitalize;
  font-size: 12px;
  color: var(--gray11);
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-left: 4px;
}

.raycast-submenu .cmdk-input {
  padding: 12px;
  font-family: var(--font-sans);
  border: 0;
  border-top: 1px solid var(--gray6);
  font-size: 13px;
  background: transparent;
  margin-top: auto;
  width: 100%;
  outline: 0;
  border-radius: 0;
}

.raycast-submenu[data-state='open'] {
  animation-name: slideIn;
}

.raycast-submenu[data-state='closed'] {
  animation-name: slideOut;
}

.raycast-submenu .cmdk-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  white-space: pre-wrap;
  font-size: 14px;
  color: var(--gray11);
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: scale(0.96);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.96);
  }
}

@media (max-width: 640px) {
  .raycast .cmdk-input {
    font-size: 16px;
  }
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1 !important;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: #00000052;
}

.cdk-overlay-transparent-backdrop {
  transition:
    visibility 1ms linear,
    opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.content {
  height: fit-content;
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 720px;
}

.content:after {
  background-image: radial-gradient(at 27% 37%, hsl(215, 98%, 61%) 0px, transparent 50%),
    radial-gradient(at 97% 21%, hsl(256, 98%, 72%) 0px, transparent 50%),
    radial-gradient(at 52% 99%, hsl(354, 98%, 61%) 0px, transparent 50%),
    radial-gradient(at 10% 29%, hsl(133, 96%, 67%) 0px, transparent 50%),
    radial-gradient(at 97% 96%, hsl(38, 60%, 74%) 0px, transparent 50%),
    radial-gradient(at 33% 50%, hsl(222, 67%, 73%) 0px, transparent 50%),
    radial-gradient(at 79% 53%, hsl(343, 68%, 79%) 0px, transparent 50%);
  position: absolute;
  content: '';
  z-index: 2;
  width: 100%;
  height: 100%;
  filter: blur(100px) saturate(150%);
  z-index: -1;
  top: 80px;
  opacity: 0.2;
  transform: translateZ(0);
}

@media (prefers-color-scheme: dark) {
  .content:after {
    opacity: 0.1;
  }
}

.info {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  flex-wrap: wrap;
  gap: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  transform: translateY(12px);
}

.githubButton,
.installButton,
.switcher button {
  height: 40px;
  color: var(--gray12);
  border-radius: 9999px;
  font-size: 14px;
  transition-duration: 0.15s;
  transition-property: background, color, transform;
  transition-timing-function: ease-in;
  will-change: transform;
}

.githubButton {
  padding: 0 12px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

.githubButton:hover {
  background: var(--grayA3);
}

.githubButton:active {
  background: var(--grayA5);
  transform: scale(0.97);
}

.githubButton:focus-visible {
  outline: 0;
  outline: 2px solid var(--gray7);
}

.githubButton svg {
  vertical-align: middle;
}

.installButton {
  background: var(--grayA3);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 8px 0 16px;
  cursor: copy;
  font-weight: 500;
}

.installButton:hover {
  background: var(--grayA4);
}

.installButton:hover span {
  background: var(--grayA5);
}

.installButton:hover span svg {
  color: var(--gray12);
}

.installButton:focus-visible {
  outline: 0;
  outline: 2px solid var(--gray7);
  outline-offset: 2px;
}

.installButton:active {
  background: var(--gray5);
  transform: scale(0.97);
}

.installButton span {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: var(--grayA3);
  border-radius: 9999px;
  transition: background 0.15s ease;
}

.installButton span svg {
  size: 16px;
  color: var(--gray11);
  transition: color 0.15s ease;
}

.switcher {
  display: grid;
  grid-template-columns: repeat(4, 100px);
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 48px 0;
  position: relative;
}

.switcher button {
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  margin: auto;
  gap: 8px;
  padding: 0 16px;
  border-radius: 9999px;
  color: var(--gray11);
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  text-transform: capitalize;
}

.switcher button:hover {
  color: var(--gray12);
}

.switcher button:active {
  transform: scale(0.96);
}

.switcher button:focus-visible {
  outline: 0;
  outline: 2px solid var(--gray7);
}

.switcher button svg {
  width: 14px;
  height: 14px;
  vertical-align: middle;
}

.switcher button[data-selected='true'] {
  color: var(--gray12);
}

.switcher button[data-selected='true']:hover .activeTheme {
  background: var(--grayA6);
}

.switcher button[data-selected='true']:active {
  transform: scale(0.96);
}

.switcher button[data-selected='true']:active .activeTheme {
  background: var(--grayA7);
}

.switcher .activeTheme {
  background: var(--grayA5);
  border-radius: 9999px;
  height: 32px;
  width: 100%;
  top: 0;
  position: absolute;
  left: 0;
}

.switcher .arrow {
  color: var(--gray11);
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
}

.header button[aria-selected] {
  background: #fff;
  color: var(--gray12);
  box-shadow:
    0 2px 5px -2px #00000026,
    0 1px 3px -1px #0003;
}

.versionBadge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--grayA11);
  background: var(--grayA3);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
}

@media (prefers-color-scheme: dark) {
  .versionBadge {
    background: var(--grayA2);
  }
}

.cmdk-logo {
  width: 48px;
}

.codeBlock {
  margin-top: 72px;
  position: relative;
}

.line {
  height: 20px;
  width: 180px;
  margin: 64px auto;
  background-image: url(/assets/line.svg);
  filter: invert(1);
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent,
    #fff 4rem,
    #fff calc(100% - 4rem),
    transparent
  );
  mask-image: linear-gradient(90deg, transparent, #fff 4rem, #fff calc(100% - 4rem), transparent);
}

@media (prefers-color-scheme: dark) {
  .line {
    filter: unset;
  }
}

.line2 {
  height: 1px;
  width: 300px;
  background: var(--gray7);
  position: absolute;
  top: 0;
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent,
    #fff 4rem,
    #fff calc(100% - 4rem),
    transparent
  );
  mask-image: linear-gradient(90deg, transparent, #fff 4rem, #fff calc(100% - 4rem), transparent);
}

.line3 {
  height: 300px;
  width: calc(100% + 32px);
  position: absolute;
  top: -16px;
  left: -16px;
  border-radius: 16px 16px 0 0;
  --size: 1px;
  --gradient: linear-gradient(to top, var(--gray1), var(--gray7));
}

.line3:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: var(--size);
  background: linear-gradient(to top, var(--gray1), var(--gray7));
  mask:
    linear-gradient(black, black) content-box,
    linear-gradient(black, black);
  mask-composite: exclude;
  transform: translateZ(0);
}

@media (prefers-color-scheme: dark) {
  .line3:before {
    mask: none;
    mask-composite: none;
    opacity: 0.2;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
}

.footerText {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  width: 100%;
}

@media (max-width: 640px) {
  .main {
    padding-top: 24px;
    padding-bottom: 120px;
  }

  .switcher {
    grid-template-columns: repeat(2, 100px);
    gap: 16px;
  }

  .switcher .arrow {
    display: none;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 32px auto;
  bottom: 16px;
  color: var(--gray11);
  font-size: 13px;
  z-index: 3;
  width: 100%;
  flex-direction: column;
}

.footer a {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: var(--gray12);
  font-weight: 500;
  border-radius: 9999px;
  padding: 4px;
  margin: 0 -2px;
  transition: background 0.15s ease;
}

.footer a:hover,
.footer a:focus-visible {
  background: var(--grayA4);
  outline: 0;
}

.footer img {
  width: 20px;
  height: 20px;
  border: 1px solid var(--gray5);
  border-radius: 9999px;
}

.cmdk-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.cmdk-wrapper > div {
  max-width: 640px;
  width: 100%;
}

.theme-switcher {
  position: fixed;
  z-index: 3;
  font-size: 24px;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

code[class*='language-'],
pre[class*='language-'] {
  color: #f8f8f2;
  background: 0 0;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  font-family:
    Consolas,
    Monaco,
    Andale Mono,
    Ubuntu Mono,
    monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*='language-'] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #272822;
}

:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
  color: #8292a2;
}

.token.punctuation {
  color: #f8f8f2;
}

.token.namespace {
  opacity: 0.7;
}

.token.constant,
.token.deleted,
.token.property,
.token.symbol,
.token.tag {
  color: #f92672;
}

.token.boolean,
.token.number {
  color: #ae81ff;
}

.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
  color: #a6e22e;
}

.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.variable {
  color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.class-name,
.token.function {
  color: #e6db74;
}

.token.keyword {
  color: #66d9ef;
}

.token.important,
.token.regex {
  color: #fd971f;
}

.token.bold,
.token.important {
  font-weight: 700;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}
